@import "../../../css/styles/variables";
@import "../../../css/styles/colors";

.update-profile {
  font-family: $font;
  color: $primary-color;
  position: relative;
  width: 100%;
  @media screen and (max-width: 1200px) {
    overflow-x: hidden;
  }
  .dimmed-background {
    background: #000;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 99999;
  }
  .member-content-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  }
  .buttons-container{
    display: flex;
    align-items: center;
    margin: 20px 0 0 40px;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 680px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .button {
      margin: 5px;
      background-color: $secondary-color;
      width: fit-content;
      white-space: nowrap;
      border-radius: 80px;
      font-weight: 400;
      color: #fff;
      box-shadow: 4px 7px 12px 0 rgba(0, 0, 0, 0.10);
      font-family: $font;
      transition: border-color .2s ease-in-out, background-color .2s ease-in-out, color .2s ease-in-out;
      &:hover {
        background-color: $secondary-color-hover;
        color: #fff;
        cursor:pointer;
      }
    }
    .save-button-container{
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      margin-right: 40px;
      border-radius: 20px;
      width: 150px;
      @media screen and (max-width: 690px) {
        position: relative;
        margin: 5px 5px;
      }
      .save-button{
        .background {
          background-color: $secondary-color;
        }
        color: #fff;
        white-space: nowrap;
        text-align: center;
      }
      .save-button-text{
        font-weight: 400;
        font-size: 16px;
        font-family: $font;
      }
    }
  }

  .cropper-avatar {
    width: 400px;
    height: 400px;
    margin: auto;
    background-color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0);
    z-index: 100000;
    visibility: hidden;
    opacity: 1;
  }
  .cropper-cover {
    width: 1120px;
    height: 400px;
    margin: auto;
    background-color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0);
    z-index: 100000;
    visibility: hidden;
    opacity: 1;
  }

  .profile-editor-container{
    margin: 50px auto 0 auto;
    background: #ffffff;
    padding: 20px 0 20px 0;
    border-radius: 20px;
    box-shadow: 0px 17px 13px 0px $box-shadow-color;
    width: 80%;
    color:$primary-color;
    .profile-details-container{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      @media screen and (max-width: 900px) {
        flex-direction: column;
        .first-column, .second-column{
          padding: 0 20px;
        }
      }
      .first-column{
          width: 45%;
          @media screen and (max-width: 900px) {
            width: 100%;
          }
          .given-name-detail{
              justify-content: stretch;
              background-color:$background-color;
              font-family: $font;
              font-weight: 400;
          }
          .family-name-detail{
              background-color:$background-color;
              font-family: $font;
              font-weight: 400;
          }
          .username-detail{
              background-color:$background-color;
              font-family: $font;
              font-weight: 400;
          }
      }
      .second-column{
          width: 45%;
          @media screen and (max-width: 900px) {
            width: 100%;
          }
      } 
    }
    
  }
}
