@import "../../../../css/styles/variables";
@import "../../../../css/styles/colors";

.member-badge {
  font-family: $font;
  max-width: 1400px;
  margin: auto;
  width: 100%;
  .member-badge-content-bottom {
    display: flex;
    flex-direction: column;
    margin: 40px 0;
    @media screen and (max-width: 979px) {
      flex-direction: column;
      padding: 15px;
      margin: 25px auto;
    }
    .profile-button-container {
      height: 80%;
      display: flex;
      padding: 0 15px;
      align-items: center;
      .profile-button {
        font-family: $font;
        cursor: pointer;
        padding: 8px 20px;
        border-radius: 30px;
        width: fit-content;
        border: 1px solid #ddd;
        background-color: $secondary-color;
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        transition: all ease-in 300ms;
      }
      .profile-button:hover {
        background-color: $secondary-color-hover;
      }
    }
    .body{
        width: 100%;
      @media screen and (max-width: 979px) {
        margin: 0;
      }
      .member-badges {
        margin: 25px 0;
        color: $primary-color;
        .title-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .header {
            margin: 15px 20px;
            font-size: 25px;
            font-weight: 700;
            display: flex;
            align-items: center;
          }
        }
        .badge-list {
          margin: 0;
          display: grid;
          align-items: stretch;
          justify-content: left;
          grid-template-columns: repeat(auto-fit, 25%);
          @media screen and (max-width: 979px) {
            grid-template-columns: repeat(auto-fit, 33%);
          }
          @media screen and (max-width: 720px) {
            grid-template-columns: repeat(auto-fit, 50%);
          }
          @media screen and (max-width: 400px) {
            grid-template-columns: repeat(auto-fit, 100%);
          }
          > li {
            margin: 10px;
          text-align: center;
          }
          .badge-item {
            height: 100%;
            .badge-item-sub {
              height: 95%;
              display: flex;
              flex-flow: column;
              .badge-details {
                display: flex;
                flex-flow: column;
                flex-grow: 1;
                .desc {
                  flex-grow: 1;
                }
              }
            }
          }
        }
        .no-badges {
          margin: 30px 0 250px;
          text-align: center;
        }
      }
      .member-badge-main-footer {
        text-align: center;
        h3 {
          margin-top: 12px;
        }
        .pagination {
          justify-content: center;
        }
      }
    }
  }
}
