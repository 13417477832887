@import "../../../css/styles/colors";
@import "../../../css/styles/variables";

.builderpal-project-item {
  display: flex;
  flex-flow:column;
  flex-grow: 1;
  width: 100%;
  align-items: center;
  padding: 0 24px;
  &:hover {
    text-decoration: none;
  }
  .builderpal-project-item-sub {
    height: 100%;
    width: 100%;
    margin: 18px 12px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: relative;
    display: flex;
    flex-flow:column;
    justify-content: space-between;
    transition: all 0.3s ease-out;
    &:hover {
      box-shadow: 0px 0px 8px 0px $light-gray-color;
      transform: scale(1.03);
    }
    .builderpal-project-image {
      display:flex;
      align-items: flex-start;
      overflow: hidden;
      object-fit: cover;
      width: 100%;
      .image {
        width: 100%;
        height: 100%;
        max-height: 192px;
        object-fit: cover;
        border-radius: 10px 10px 0 0;
      }
    }
    .builderpal-project-details {
      flex: 1;
      padding: 18px 18px 8px 18px;
      display: flex;
      flex-direction: column;
      .name {
        margin-bottom: 18px;
        .text {
          color: #3e3f5e;
          font-size: 20px;
          font-weight: 700;
        }
      }
      .desc {
        margin-bottom: 12px;
        color: #666;
        font-size: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px;
        font-weight: 500;
      }
      .creator {
        margin: 0 0 20px 0;
        color: #a2a2a2;
        font-size: 12px;
        overflow-y: hidden;
      }
    }
    .earned-builderpal-project-users {
      width: 100%;
      border: 1px solid #eaeaf5;
      border-width: 1px 0 0 0;
      padding: 12px;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 56px;
      .difficulty {
        font-size: 16px;
        font-weight: 500;
        color: $primary-color;
        display:inline-block;
        padding: 8px;
        border-radius: 10px;
        display: flex;
        img {
          height: 18px;
        }
        @media screen and (max-width: 750px) {
          padding: 8px 10px;
        }
      }
      .stars {
        display:inline-block;
        margin-right: 10px;
        vertical-align: middle;
        display: flex;
        align-items: center;
        @media screen and (max-width: 1280px) {
          vertical-align: baseline;
        }
        @media screen and (max-width: 750px) {
          margin-right: 5px;
        }
        @media screen and (max-width: 300px) {
          margin-right: 5px;
        }
      }
      .level {
        display:inline-block;
        flex-wrap: nowrap;
        overflow:hidden;
        @media screen and (max-width: 420px) {
          font-size: 12px;
        }
      }
    }
    .like-button {
      align-items: center;
      position: absolute;
      top: 12px;
      right: 16px;
      display: flex;
      border-radius: 20px;
      transition: all 0.2s ease-in-out;
      .heart-button {
        width: 28px;
        height: 28px;
        path {
          stroke: #fff;
        }
      }
      .saved {
        fill: rgb(248,48,95);
        path {
          stroke-width: 0;
        }
        transform: scale(1.03);
      }
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
