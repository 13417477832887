
$mobile-width: 979px;
$desktop-width: $mobile-width+1;

$header-height: 60px;
$navi-height: 40px;
$header-z-index: 50;
$content-padding: 40px;
$content-padding-mobile: 48+0px 10px 10px;
// $content-max-width: 1160px;
$content-max-width: 1370px;

$cubic-bezier-default: cubic-bezier(0.65, 0.22, 0.13, 0.93);
$cubic-bezier-default-reverse: cubic-bezier(0.93, 0.13, 0.22, 0.65);
// $loading-cubic-bezier: cubic-bezier(0.62, 0.41, 0.34, 0.64);
$loading-cubic-bezier: cubic-bezier(0.75, 0.35, 0.34, 0.64);

$ios-present-cubic-bezier: cubic-bezier(0.95, 0.29, 0.59, 0.79);


$font: "circular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";