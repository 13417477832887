@import "../../../css/styles/variables";
@import "../../../css/styles/colors";


.challenge-page {
  font-family: $font;
  .challenge-header-container{
    width:100%;
    height: 600px;
    min-width: fit-content;
    position: relative;
    .chat-container {
      display: flex;
      align-items: center;
      position: absolute;
      top: 35px;
      right: 20px;
      cursor: pointer;
      @media screen and (max-width: 979px) {
        top: 30px;
        right: 10px;
      }
      @media screen and (max-width: 600px) {
        top: 30px;
        right: 10px;
      }
      .speech-bubble {
        height: 100px;
        width: 180px;
        @media screen and (max-width: 979px) {
          height: 80px;
          width: 150px;
        }
      }
      .chat-content {
        position: absolute;
        top: 15px;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        @media screen and (max-width: 979px) {
          top: 13px;
        }
        .profile-image {
          margin: 0 13px;
          img {
            border-radius: 50%;
            height: 45px;
            width: 45px;
            @media screen and (max-width: 979px) {
              height: 35px;
              width: 35px;
            }
          }
        }
        .button-text {
          color: white;
          margin-top: 2px;
          @media screen and (max-width: 979px) {
            font-size: 14px;
          }
        }
      }
    }
    .cover-image {
      height: 100%;
      width: 100%;
      background-color: #fff;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .challenge-details {
      border-radius: 12px;
      background-color: #fff;
      padding: 24px;
      display: flex;
      font-family: $font;
      top: 400px;
      position: absolute;
      margin-left: 40px;
      width: calc(100% - 80px);
      min-width: fit-content;
      top: auto;
      bottom: 50px;
      @media screen and (max-width: 979px) {
        padding: 15px;
      }
      @media screen and (max-width: 900px) {
        flex-direction: column;
        align-items: center;
        margin: 40px 0 0 0;
        width: 100%;
        border-radius: 0;
      }
      @media screen and (max-width: 600px) {
        bottom: 20px;
      }
      @media screen and (max-width: 400px) {
        padding: 15px 0px;
      }
      .basic-challenge-info{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        @media screen and (max-width: 900px) {
          flex-direction: column;
        }
      }
      .challenge-image-container {
        display: flex;
        justify-content: center;
        margin: 8px;
        width: 120px;
        height: 90px;
        img {
          border-radius: 10px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .text {
        margin: auto 0 auto 20px;
        font-family: $font;
        @media screen and (max-width: 900px) {
          margin: 0px;
        }
        @media screen and (max-width: 414px) {
          margin-left: -3px;
        }
        @media screen and (max-width: 380px) {
          margin-left: -17px;
        }
        &.description {
          margin-right: 30px;
          @media screen and (max-width: 1400px) {
            margin-right: 10px;
          }
          @media screen and (max-width: 600px) {
            margin-bottom: 15px;
          }
        }
        .name {
          font-size: 25px;
          font-weight: 500;
          color: $primary-color;
          max-width: 300px;
          min-width: fit-content;
          img {
            height: 30px;
          }
          @media screen and (max-width: 1280px) {
            font-size: 1.9vw;
            max-width: 20vw;
          }
          @media screen and (max-width: 979px) {
            font-size: 1.7vw;
          }
          @media screen and (max-width: 900px) {
            font-size: 17px;
            margin-top: 10px;
            text-align: center;
            max-width: none;
          }
        }
        .desc {
          font-size: 16px;
          margin: 5px 0;
          font-weight: 400;
          max-width: 300px;
          @media screen and (max-width: 1280px) {
            font-size: 1.4vw;
            max-width: 20vw;
          }
          @media screen and (max-width: 900px) {
            font-size: 14px;
            margin-bottom: 20px;
            text-align: center;
            max-width: none;
          }
        }
      }
      .challenge-specs-container {
        display: flex;
        margin-left: auto;
        margin-right: 0px;
        flex-wrap:wrap;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        @media screen and (max-width: 900px) {
          margin-left: 0px;
        }
        @media screen and (max-width: 900px) {
          margin-left: 0px;
          display: flex;
          flex-direction: column;
        }
        .challenge-spec {
          margin: 5px;
          width: fit-content;
          font-size: 20px;
          font-weight: 500;
          color: $primary-color;
          background-color: #ececec;
          border-radius: 20px;
          padding: 5px 15px;
          display: flex;
          align-items: center;
          width:fit-content;
          img {
            height: 25px;
          }
          @media screen and (max-width: 1280px) {
            img {
              height: 1.7vw;
            }
          }
          @media screen and (max-width: 979px) {
            img {
              height: 1.6vw;
            }
            font-size: 1.6vw;
          }
          @media screen and (max-width: 900px) {
            img {
              height: 17px;
            }
            font-size: 14px;
          }
          .spec-icon {
            display:inline-block;
            margin-right: 10px;
            vertical-align: middle;
            display: flex;
            align-items: center;
            @media screen and (max-width: 1280px) {
              vertical-align: baseline;
            }
          }
        }
      }
    }
  }
  .challenge-participation-stats-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 10px 0 0 0;
    @media screen and (min-width: 600px) {
      display:none;
    }
    @media screen and (max-width: 400px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .separator-container{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width:100%;
      background-color: #fff;
      @media screen and (max-width: 600px) {
        padding: 20px 20px;
      }
      @media screen and (max-width: 400px) {
        justify-content: center;
        padding: 0 20px;
      }
      .info {
        display: flex;
        align-items: center;
        margin-right: 24px;
        .info-list {
          display: flex;
          justify-content: flex-end;
          flex-direction: row-reverse;
          align-items: center;
          .info-list-text {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 4px;
            border-radius: 50%;
            font-size: 10px;
            background-color: #fff;
            box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
          }
        }
        .info-text {
          margin-left: 15px;
          font-size: 14px;
          font-weight: 700;
          @media screen and (max-width: 600px) {
            font-size: 13px;
            margin-left: 2px;
            box-shadow: 5px 5px #ecf1f9;
            padding: 5px;
            border: 1px solid #ecf1f9;
            border-radius: 10px;
            font-weight: 500;
            text-align: center;
            width: 100px;
          }
        }
        
      }
    }
    .actions {
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      display: flex;
      padding: 10px;
      .action-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 220px;
        height: 44px;
        border: 1px solid #ddd;
        border-radius: 80px;
        background-color: $secondary-color;
        cursor: pointer;
        margin: 10px 20px;
        box-shadow: 3px 5px 20px 0 rgba(94, 92, 154, 0.1);
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        .action-button-text{
          color: #fff;
          font-size: 18px;
          font-weight: 400;
        }
        svg {
          fill: #fff;
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }
      }
      @media screen and (min-width: 401px) {
        .action-button {
          width: 44px;
          @media screen and (max-width: 400px) {
            margin: 10px;
          }
          .action-button-text {
            display: none;
          }
          svg {
            margin: 0;
          }
        }
      }
    }
  }
  .challenge-participation-stats {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 12px 0 40px;
    border-top: 1px solid #eaeaf5;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #fcfcfd;
    transition: padding 0.2s ease-in-out;
    @media screen and (max-width: 900px) {
      padding: 0 12px 0 20px;
      border-radius: 0;
    }
    @media screen and (max-width: 600px) {
      display:none;
    }
    .separator-container{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 20px 50px;
      width:100%;
      background-color: #fff;
      @media screen and (max-width: 600px) {
        padding: 20px 20px;
        justify-content: center;
      }
      @media screen and (max-width: 414px) {
        margin-left: -2vw;
       
      }
      .info {
        display: flex;
        align-items: center;
        margin-right: 24px;
        .info-list {
          display: flex;
          justify-content: flex-end;
          flex-direction: row-reverse;
          align-items: center;
          .info-list-text {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 4px;
            border-radius: 50%;
            font-size: 10px;
            background-color: #fff;
            box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
          }
          .info-list-profile {
            width: 36px;
            height: 36px;
            margin-left: -8px;
            border-radius: 50%;
            box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
            transition: width 0.2s ease-in-out, height 0.2s ease-in-out, margin-left 0.2s ease-in-out;
            &:last-child {
              margin-left: 0;
            }
            .image {
              border-radius: 50%;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
            @media screen and (max-width: 800px) {
              display: none;
            }
          }
        }
        .info-text {
          margin-left: 15px;
          font-size: 14px;
          font-weight: 700;
          @media screen and (max-width: 600px) {
            font-size: 13px;
            margin-left: 2px;
            box-shadow: 5px 5px #ecf1f9;
            padding: 5px;
            border: 1px solid #ecf1f9;
            border-radius: 10px;
            font-weight: 500;
            text-align: center;
            // width: 7em;
            width: 100px;
          }
        }
        
      }
    }
    .actions {
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      display: flex;
      padding: 10px;
      .action-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 220px;
        height: 44px;
        border: 1px solid #ddd;
        border-radius: 80px;
        background-color: $secondary-color;
        cursor: pointer;
        margin: 10px 20px;
        box-shadow: 3px 5px 20px 0 rgba(94, 92, 154, 0.1);
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        .action-button-text{
          color: #fff;
          font-size: 18px;
          font-weight: 400;
        }
        svg {
          fill: #fff;
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }
      }
      @media screen and (max-width: 979px) {
        .info:last-child {
          display: flex;
        }
      }
      @media screen and (max-width: 800px) {
        .action-button {
          width: 44px;
          @media screen and (max-width: 390px) {
            margin: 10px;
          }
          .action-button-text {
            display: none;
          }
          svg {
            margin: 0;
          }
        }
      }
    }
  }
  .body{
    padding: $content-padding;
    color: $primary-color;
    max-width: 1400px;
    @media screen and (min-width: 1400px) {
      width: 1400px;
      margin: 0 auto;
    }
    @media screen and (max-width: 900px) {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .challenge-info {
      margin: 20px 0 0 0;
      display: flex;
      @media screen and (max-width: 900px) {
        flex-direction: column;
        align-items: center;
        margin: 40px 0;
        width: 100%;
        border-radius: 0;
      }
      .challenge-info-container {
        flex: 1;
        width: 100%;
        .challenge-container {
          width: 100%;
          padding: 40px;
          background-color: #fff;
          color: $primary-color;
          border-radius: 12px;
          margin-bottom: 40px;
          display: flex;
          @media screen and (max-width: 900px) {
            border-radius: 0;
          }
          @media screen and (max-width: 600px) {
            padding: 30px;
          }
          img {
            height: 50px;
            margin-right: 20px;
            @media screen and (max-width: 600px) {
              height: 34px;
            }
          }
          .title {
            font-size: 28px;
            font-weight: 600;
            @media screen and (max-width: 1280px) {
              font-size: 24px;
            }
            @media screen and (max-width: 979px) {
              font-size: 20px;
            }
          }
          .name {
            margin-top: 15px;
            @media screen and (max-width: 979px) {
              font-size: 15px;
            }
            @media screen and (max-width: 600px) {
              left: -50px;
              position: relative;
            }
          }
        }
        .how-to-earn-challenge {
          width: 100%;
          padding: 40px;
          background-color: #fff;
          color: $primary-color;
          border-radius: 12px;
          margin-bottom: 40px;
          display: flex;
          // font-family: $font;
          @media screen and (max-width: 900px) {
            border-radius: 0;
          }
          @media screen and (max-width: 600px) {
            padding: 30px;;
          }
          img {
            height: 50px;
            margin-right: 20px;
            @media screen and (max-width: 600px) {
              height: 34px;
            }
          }
          .title {
            font-size: 28px;
            font-weight: 600;
            @media screen and (max-width: 1280px) {
              font-size: 24px;
            }
            @media screen and (max-width: 979px) {
              font-size: 20px;
            }
          }
          .rdw-editor-wrapper {
            @media screen and (max-width: 600px) {
              position: relative;
              left: -50px;
              width: 80vw;
            }
          }
        }
        .challenge-badges,
        .challenge-projects,
        .challenge-earners {
          margin: 20px 0;
          .mini-title {
            margin: 40px 20px;
            font-size: 20px;
            font-weight: 700;
            .submit-link {
              color:$secondary-color;
              &:hover{
                color: $secondary-color-hover;
                text-decoration: underline;
              }
            }
          }
          > ul {
            margin: 0;
            display: flex;
            > li {
              margin: 0;
              width: 33.3%;
            }
          }
          .projects-list {
            margin: 0;
            display: grid;
            align-items: stretch;
            justify-content: center;
            grid-template-columns: repeat(auto-fit, 33%);
            @media screen and (max-width: 979px) {
              grid-template-columns: repeat(auto-fit, 50%);
            }
            @media screen and (max-width: 900px) {
              grid-template-columns: repeat(auto-fit, 100%);
            }
            > li {
              margin: 0;
              height: 100%;
              width: 100%;
              display: flex;
              flex-flow: column;
              align-items: center;
            }
          }
          .challenges-list {
            margin: 0;
            display: grid;
            align-items: stretch;
            justify-content: center;
            grid-template-columns: repeat(auto-fit, (20%));
            @media screen and (max-width: 979px) {
              grid-template-columns: repeat(auto-fit, 25%);
            }
            @media screen and (max-width: 720px) {
              grid-template-columns: repeat(auto-fit, 50%);
            }
            @media screen and (max-width: 480px) {
              grid-template-columns: repeat(auto-fit, 100%);
            }
            > li {
              margin: 0;
              height: 100%;
              width: 100%;
              display: flex;
              flex-flow: column;
              align-items: center;
              .badge-item {
                width: 100%;
              }
            }
          }
          .challenge-projects-main-footer {
            text-align: center;
            h3 {
              margin-top: 12px;
            }
            .pagination {
              justify-content: center;
            }
          }
  
        }
        .hide-in-app {
          display: none;
        }
  
        figure {
          text-align: center;
  
          iframe {
            width: 99%;
            aspect-ratio: 16 / 9;
            min-height: 36vw;
            border-radius: 10px;
  
            @media screen and (max-width: 979px) {
              min-height: 48vw;
            }
          }
        }
      }
      .challenge-did-you-know {
        width: 272px;
        margin: 0 0 0 20px;
        .challenge-did-you-know-container {
          border-radius: 12px;
          background-color: #fff;
          padding: 20px;
          .title {
            font-size: 20px;
            font-weight: 700;
          }
          .challenge-user-list {
            display: flex;
            margin: 12px 0 0 0;
            li {
              position: relative;
              z-index: 5;
              margin: 0;
              width: 18px;
              img {
                height: 24px;
                width: 24px;
                border-radius: 50%;
                box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
              }
              &:hover {
                z-index: 10;
                img {
                  background-color: #fff;
                  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.5);
                }
              }
            }
          }
        }
        @media screen and (max-width: 979px) {
          margin: 20px 0 0 0;
          width: 100%;
        }
      }
    }
  }

  span {
    @media screen and (max-width: 979px) {
      font-size: 15px;
    }
  }
  .banner {
    &.hide-in-app {
      display: none;
    }
  }
  &.hide-in-app {
    padding-top: 20px;
  }


  .chat-bubble {
    position: fixed;
    z-index: 999;
    bottom: 48px;
    right: 48px;
    border-radius: 36px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #23d2e2;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border: 0;
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
      fill: #fff;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .chat-bot {
    font-family: Rajdhani,sans-serif;
    color: #3e3f5e;
    position: fixed;
    z-index: 1000;
    bottom: 36px;
    right: 36px;
    width: 100%;
    height: 640px;
    max-width: 464px;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    transform: translateX(128%);
    transition: transform 0.5s ease-in-out;
    .close-button {
      position: absolute;
      top: 12px;
      right: 12px;
      border: 0;
      background-color: #fff;
      width: 36px;
      height: 36px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: #eee;
      }
    }
    @media screen and (max-width: 545px) {
      right: 12px;
    }
    @media screen and (max-width: 464px) {
      right: 0;
    }
  }
  .active {
    transform: translateX(0);
  }
}

.rdw-embedded-wrapper {
  img {
    content: url("../../../css/imgs/youtube-icon.png");
    width: 17px;
  }
}
.rdw-embedded-modal-header span {
  display: none;
}

.rdw-embedded-modal-header:after {
  content: "Youtube Video Link";
}

img.rdw-link-decorator-icon {
  visibility: hidden;
}

.rdw-image-imagewrapper img {
  max-width: 100%;
}
