$rocket-size: 160px;
$resize-width: 979px;

.banner{
  padding: 40px 0;
  margin: 0px auto 40px;
  min-height: 148px;
  border-radius: 12px;
  position: relative;
  display: flex;
  width: 100%;
  @media screen and (max-width: 1365px) {
    min-height: auto;
    height: auto;
    padding: 15px 0;
  }
  @media screen and (max-width: 967px) {
    min-height: auto;
    width: 98.5%;
    height: 100%;
    padding: 15px 0;
  }
  @media screen and (max-width: 720px) {
    min-height: auto;
    width: 98%;
    padding: 15px 0;
  }
  @media screen and (max-width: 420px) {
    min-height: auto;
    width: 97%;
    padding: 15px 0;
  }
  @media screen and (max-width: 300px) {
    min-height: auto;
    width: 100%;
    padding: 5px 0;
  }
  .background{
    border-radius: 12px;
    height: 100%;
    width: 100%;
    bottom: 0px;
    position: absolute;
    background-image: url('../../../css/imgs/boom-imgs/banner/banner-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
  }
  .overlay-image{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: block;
    height: 100%;
    width: 100%;
    background-size: contain;
    background-position: 0% 0%;
    background-repeat: no-repeat;
  }
  &.rocket-icon .overlay-image
  {
    background-image: url('../../../css/imgs/boom-imgs/banner/overview-icon.png');
  }
  &.badge-icon .overlay-image{
    background-image: url('../../../css/imgs/boom-imgs/banner/badges-icon.png');
  }
  &.settings-icon .overlay-image{
    background-image: url('../../../css/imgs/boom-imgs/banner/accounthub-icon.png');
  }
  &.members-icon .overlay-image{
    background-image: url('../../../css/imgs/boom-imgs/banner/members-icon.png');
  }
  &.booking-icon .overlay-image{
    background-image: url('../../../css/imgs/boom-imgs/banner/events-icon.png');
  }
  &.boomisland-icon {
    @media screen and (min-width: 700px) {
      // height: 60px;
      padding: 40px 0;
      min-height: 148px;
      height: 100%;
    }
    .background {
      background-image: url('../../../css/imgs/boom-imgs/banner/boomisland-banner.jpg');
      background-position: center;
    }
  }
  &.myfit-icon {
    @media screen and (min-width: 700px) {
      padding: 40px 0;
      min-height: 148px;
      height: 100%;
    }
    .background{
      background-image: url('../../../css/imgs/boom-imgs/banner/myfit-banner.jpg');
    }
  } 
  &.logo-icon {
    @media screen and (min-width: 700px) {
      padding: 40px 0;
      min-height: 148px;
      height: 100%;
    }
    .background{
      background-image: url('../../../css/imgs/boom-imgs/banner/logo-banner.jpg');
      background-position: 35%;
    }
  } 

  .text{
    position: relative;
    z-index: 2;
    padding-left: $rocket-size + 12px;
    color: #FFF;
    @media screen and (max-width: $resize-width) {
      padding-left: $rocket-size - 50px
    }
    .title{
      font-weight: 700;
      font-size: 36px;
      // margin-bottom: 0px;
      font-size: 2.25rem;
      font-weight: 700;
      @media screen and (max-width: $resize-width) {
        font-size: 1.5rem;
        // margin-bottom: 2px;
      }
    }
    .title-sub{
      font-size: 1rem;
      font-weight: 500;
      @media screen and (max-width: $resize-width) {
        font-size: .75rem;
      }
    }
  }
}