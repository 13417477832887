@import "../../../../css/styles/variables";
@import "../../../../css/styles/colors";

.project-covers {
  margin-bottom: 20px;
  .no-margin {
    margin: 0;
  }
  .item-row {
    display: flex;
    align-items: center;
    > ul {
      max-width: 80%;
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      align-items: center;
      margin: 20px;
      min-width: 360px;
      height: 150px;
      > li {
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        margin: 0 8px;
        display: flex;
        // border: 2px solid #aaa;
        .image {
          border-radius: 12px;
          margin: auto;
          object-fit: cover;
          height: 150px;
          width: 200px;
          border: 5px solid #fff;
        }
      }
      .selected-cover {
        .image {
          border-radius: 12px;
          margin: auto;
          object-fit: cover;
          height: 150px;
          width: 200px;
          border: 5px solid $secondary-color;
        }
      }
    }
  }
}