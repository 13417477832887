


.user-list__container {
  color: var(--text-color);

  .user-list__header-title {
    border-bottom: 1px solid var(--border-color);
    .user-list__header-title-left {
      font: var(--font-weight-regular) 16px;
    }
    .user-list__input {
      .user-list__input__wrapper {
        background: var(--panel-primary-background-color);
        border-radius: 8px;
        border: var(--border-stroke);
      
        &:focus-within {
          border: 1px solid var(--primary-color);
          outline: none;
        }
      
        input {
          background: none;
          border: none;
          outline: none;
          font-size: 16px;
        }
      
        input::placeholder {
          font-weight: var(--font-weight-light);
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}

.user-list__message {
  font-size: 16px;
}

.user-list__row.user-list__header {

  p {
    color: var(--text-low-emphasis-color);
  }
}

.user-list__row {

  &:hover {
    background: var(--panel-primary-background-color);
    cursor: pointer;
  }

  p {
    font: var(--font-weight-regular) 14px/17px var(--font-family);
    color: var(--text-color);
  }

  .user-item__name {
    font-weight: 500;
  }
}
