@import '../../../css/styles/colors';

.pagination{
  margin: 12px 0;
  display: flex;
    > div{
      // border: 1px solid #33404d;
      border-radius: 80px;
      margin: 0 8px 0 0;
      height: 40px;
      width: 40px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      &:hover{ box-shadow: inset 0 0 250px 0 rgba(0,0,0,.15);}
      // &:active{ box-shadow: inset 0 0 250px 0 rgba(0,0,0,.3);}
      &.active{ background-color: $secondary-color; color: #FFF;}
    }
}