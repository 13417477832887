@import "../WorkspaceHeader";
@import "../utils";

.team-channel-header__container {
  @include workspace-header-layout;
  @media only screen and (max-width: 930px) {
    padding: 0 6px;
    gap: 0;
  }
}

.team-channel-header__left {
  display: flex;
  flex-direction: row;
  align-items: center;
  .back-arrow {
    @media only screen and (min-width: 930px) {
      display: none;
    }
  }
}

.team-channel-header__name {
  @include ellipsis-text;
}

.team-channel-header__name.user {
  font: var(--font-weight-medium);
  font-size: 14px;
}

