@import "../../../css/styles/variables";
@import "../../../css/styles/colors";

.home-page-event {
  margin: 0;
  height: 100%;
  display: flex;
  flex-flow: column;

  .home-navi-sub {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-flow: column;
    height: 100%;
    margin: 12px;
    border-radius: 12px;
    cursor: pointer;
    background-color: #fff;
    transition: all 0.3s ease-out;

    &:hover {
      box-shadow: 0px 0px 8px 0px $light-gray-color;
      transform: scale(1.03);
    }

    .workshop-image {
      min-width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-self: flex-start;

      .image {
        // object-fit: cover;
        width: 100%;
        aspect-ratio: 1.5;
        min-height: 160px;
      }

      // @media screen and (max-width: 979px) {
      //   height: 200px;
      // }
      // @media screen and (max-width: 630px) {
      //   grid-template-columns: repeat(auto-fit, 100%);
      // }
    }

    .text {
      text-align: left;
      margin: -36px 8px 16px 8px;
      padding: 12px;
      display: flex;
      flex-flow: column;
      // flex-grow: 1;
      text-align: center;

      // white-space: pre-wrap;
      &.workshop-desc {
        height: 100%;
        cursor: default;
      }

      .duration {
        padding-top: 10px;
      }

      h3 {
        margin-bottom: 0;
      }

      p {
        display: none;
      }

      p:first-of-type {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      p.show {
        display: block;
      }

      .button-element-container {
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        .gap-fill {
          flex-grow: 1;
        }
      }
    }

    .date-sticker {
      position: relative;
      left: 5%;
      bottom: 36px;
      border-radius: 12px;
      width: 44px;
      z-index: 2;

      .date-sticker-day {
        height: 36px;
        background-color: rgba(115, 83, 255, 0.5);
        color: #ffffff;
        border-radius: 12px 12px 0 0;
        text-align: center;
        padding: 6px 0 6px 0;
        font-size: 20px;
        font-weight: 700;
      }

      .date-sticker-month {
        height: 24px;
        padding: 4px 0;
        text-align: center;
        background-color: $secondary-color;
        border-radius: 0 0 12px 12px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
}