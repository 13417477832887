.sidebar {
  box-shadow: inset 1px 0 0 var(--border-color);

  .sidebar__company-selection-bar {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--primary-color);
    box-shadow: 1px 0 0 rgba(0, 0, 0, 0.25);

    .sidebar__company-badge {
      background: linear-gradient(150.64deg, var(--border-color) 12.73%, rgba(0, 0, 0, 0) 89.32%), var(--static-background-color);
      border-radius: var(--str-chat__border-radius-circle);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.33);
      img {
        width: 28px;
        height: 28px;
        border-radius: 8px;
      }
    }
  }

  .channel-list-bar {
    background: var(--primary-color);

    .channel-list-bar__header__text {
      font-weight: var(--font-weight-bold);
      font-size: 18px;
      line-height: 28px;
      color: white;
    }
  }
}



