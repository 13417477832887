/*------------------------------------------------------------------
[Table of contents]

1. RAW COLORS
2. SOCIAL COLORS
-------------------------------------------------------------------*/
/*---------------------
    1. RAW COLORS
---------------------*/
$white: #fff;
$white-dark: #fcfcfd;
$white-darker: #f5f5fa;

$gray-dark: #8f91ac;
$gray: #adafca;
$gray-light: #dedeea;
$gray-lighter: #eaeaf5;

$black: #3e3f5e;
$black-dark: #2e2e47;
$black-dark-mid: #302f45;
$black-darker: #15151f;

$cyan-dark: #00c7d9;
$cyan: #23d2e2;
$cyan-light: #41efff;
$cyan-hover: #1bc5d4;

$violet-darker: #45437f;
$violet-dark: #4e4ac8;
$violet: #615dfa;
$violet-hover: #5753e4;

$green: #1df377;

$red: #f9515c;
$red-light: #ff5384;
$red-dark: #fd434f;
$red-dark-mid: #fd4350;
$red-darker: #f1315e;
$red-hover: #dd343f;

$blue: #4f8dff;

$light-blue: #3ad2fe;
$light-blue-dark: #35cdff;

$yellow: #ffe00d;
$yellow-light: #fff10d;

/*-----------------------
    1. SOCIAL COLORS
-----------------------*/
$color-facebook: #3763d2;

$color-twitter: #1abcff;
$color-twitter-hover: #0aabed;
$color-twitter-shadow: 3px 5px 10px 0px rgba(26, 188, 255, .2);

$color-twitch: #7b5dfa;
$color-twitch-hover: #6a4fdf;
$color-twitch-shadow: 4px 7px 12px 0 rgba(123, 93, 250, .2);

$color-youtube: #fd434f;

$color-instagram: #f8468d;

$color-patreon: #ff7a51;

$color-discord: #7289da;

$color-google: #ffc529;

$color-deviantart: #32e1d3;

$color-behance: #3486f5;

$color-artstation: #00e194;

$color-dribbble: #f761ab;
