@import "../WorkspaceHeader";
@import "../utils";

.admin-panel__form {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f2f2f2;

  .admin-panel__form-header {
    @include workspace-header-layout;
  }

  .admin-panel__form-footer {
    height: 82px;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      padding: 10px 20px;
      margin-right: 30px;
    }
  }

  .channel-name-input-field {
    display: flex;
    flex-direction: column;
    height: 169px;
    padding-left: 20px;

    h2 {
      display: flex;
      align-items: baseline;
      gap: 10px;
      margin: 0;
      padding-block: 30px 16px;
    }

    input {
      height: 50px;
      width: 100%;
      max-width: 540px;
      padding-left: 16px;
    }
  }
}




