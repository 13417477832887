@use '../utils';

.channel-preview {
  display: flex;
  align-items: center;
  width: 100%;
  height: 37px;
  padding: 0;

  &.selected {
    display: flex;
    align-items: center;
    height: 37px;
  }
}

.channel-preview__item {
  display: flex;
  align-items: center;
  max-width: 220px;
  height: 100%;
  width: 100%;
  padding-left: 26px;
  position: relative;

  p {
    @include ellipsis-text;
  }

  &.single .str-chat__avatar {
    margin-right: 12px;
  }

  &.multi .str-chat__avatar {
    margin-right: 0;
  }

  &.multi span:first-child {
    position: relative;
    z-index: 1;
    bottom: 2px;
  }

  &.multi div:nth-child(2) {
    position: relative;
    right: 6px;
    bottom: -4px;
    z-index: 2;
    margin-bottom: 0;
    width: 14px;
    height: 14px;
    font-size: 10px;
    background-color: var(--primary-color);
    border-radius: 4px;
    margin-right: 4px;
  }
  .channel-unread-container {
    position: absolute;
    right: 0;
    z-index: 3;
    width: 40px;
    background: linear-gradient(0.25turn, transparent, var(--primary-color));
    .channel-unread {
      margin-left: auto;
      width: 18px;
      height: 18px;
      font-size: 12px;
      background-color: #fff;
      color: var(--primary-color);
      border-radius: 6px;
      text-align: center;
      line-height: 18px;
      vertical-align: middle;
    }
  }
}
