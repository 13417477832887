@import "../../../css/styles/variables";
@import "../../../css/styles/colors";


$navi-height: 75px;
$navi-width: 260px;

.claim-reward {
  .claim-reward-background{
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    position: absolute;
    background-image: url('../../../css/imgs/reward-background.jpg');
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    z-index: 0;
  }
  .reward-claim-content{
    position: relative;
    display: flex;
    flex-flow: column;
    overflow: auto;
    .reward-claim-info{
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;
      background-color: #fff;
      width: 60%;
      border-radius: 10px;
      @media screen and (max-width: 500px) {
        width: 90%;
      }
      .reward-info-container{
        padding: 30px 40px;
        .rewards-title{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 30px;
          font-weight: bold;
          border-bottom: 2px solid #e2eef1;
        }
        .rewards-failed-title{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: $secondary-color;
          font-size: 40px;
          font-weight: bold;
          text-align: center;
          @media screen and (max-width: 650px) {
            font-size: 35px;
          }
          @media screen and (max-width: 600px) {
            font-size: 28px;
          }
          @media screen and (max-width: 500px) {
            font-size: 20px;
          }
        }
        .rewards-failed-subtitle{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            color: #000;
            text-align: center;
            @media screen and (max-width: 650px) {
              font-size: 18px;
            }
            @media screen and (max-width: 600px) {
              font-size: 15px;
            }
            @media screen and (max-width: 500px) {
              font-size: 12px;
            }
        }
        .rewards-successful-title{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: $secondary-color;
          font-size: 40px;
          font-weight: bold;
          text-align: center;
          @media screen and (max-width: 650px) {
            font-size: 35px;
          }
          @media screen and (max-width: 600px) {
            font-size: 28px;
          }
          @media screen and (max-width: 500px) {
            font-size: 20px;
          }
        }
        .rewards-successful-subtitle{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            color: #000;
            text-align: center;
            @media screen and (max-width: 650px) {
              font-size: 18px;
            }
            @media screen and (max-width: 600px) {
              font-size: 15px;
            }
            @media screen and (max-width: 500px) {
              font-size: 12px;
            }
        }
        .rewards-successful-text{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            color: #858585;
            text-align: center;
            margin-top: 10px;
            @media screen and (max-width: 650px) {
              font-size: 18px;
            }
            @media screen and (max-width: 600px) {
              font-size: 15px;
            }
            @media screen and (max-width: 500px) {
              font-size: 12px;
            }
        }
        .rewards-successful-text-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .rewards-successful-text{
            font-size: 20px;
            @media screen and (max-width: 600px) {
              font-size: 12px;
            }
        }
        .reward-text-container{
            border-radius: 10px;
            padding: 50px 0 20px 0;
            text-align: center;
            .rewards-subtitle{
              font-size: 25px;
            }
            .rewards-text{
                font-size: 15px;
            }
        }
      }
      .reward-code-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 10px;
        padding: 10px;
        text-align: center;
        .reward-code-container{
          margin: 0 15px;
        }
        .reward-code-input{
            background-color: #e2eef1;
            display: flex;
            justify-content: center;
            text-align: center;
        }
        ::-webkit-input-placeholder {
            text-align: center;
            color: $primary-color
            
        }
        input:focus::placeholder {
            color: transparent;
        }
      }
      .separator-container{
        display: flex;
        justify-content: center;
        @media screen and (max-width: 500px) {
          justify-content: center;
        }
        .button {
          background-color: $secondary-color;
          box-shadow: 4px 7px 12px 0 rgba(97, 93, 250, 0.2);
          width: 15%;
          min-width: 150px;
          margin: 20px 50px;
          white-space: nowrap;
          border-radius: 80px;
          font-size: 15px;
          transition: all 0.2s ease-in-out;
          &:hover {
            box-shadow: 0px 0px 8px 0px $light-gray-color;
            transform: scale(1.03);
          }
        }
      }
    }
  }
}




