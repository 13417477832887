.builder-pal-related-projects {
  color: #3e3f5e;
  font-family: Rajdhani,sans-serif;
  line-height: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  .builder-pal {
    max-width: 1280px;
    margin-top: 32px;
    width: 100%;
    padding: 18px;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 1.6rem;
      font-weight: 700;
      margin-top: 8px;
    }
  }
}

.builder-pal-related-projects-container {
  display: flex;
  flex-direction: row;
  margin-top: 36px;
  @media screen and (max-width: 979px) {
    flex-direction: column;
    align-items: center;
  }
  .project-prompt-container {
    position: relative;
    width: 100%;
    max-width: 324px;
    .project-prompt {
      border-radius: 20px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px;
      .guiding-title {
        font-size: 21px;
        font-weight: 700;
        margin: 24px 0 9px 0;
        line-height: 24px;
      }
      .prompt-button {
        height: 48px;
        margin: 9px 0;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .background {
          background-color: #23d2e2;
        }
      }
    }
    .home {
      position: absolute;
      top: 28px;
      left: 28px;
      width: 36px;
      height: 36px;
      margin-right: 18px;
      .background {
        background-color: #23d2e2;
      }
      button {
        width: 100%;
        height: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          fill: #fff;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .projects-container {
    flex: 1;
    margin: 0 0 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 979px) {
      margin: 0;
    }
    .project-list {
      width: 100%;
      display: grid;
      align-items: stretch;
      justify-items: center;
      justify-content: space-evenly;
      grid-template-columns: repeat(auto-fit, 50%);
      @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(auto-fit, 100%);
      }
      > div {
        margin: 0;
        height: 100%;
      }
      a {
        display: flex;
        flex-flow: column;
        flex-grow: 1;
        height: 100%;
      }
    }
    .loader-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 24px;
      opacity: 0.8;
      height: 40px;
      .loader-text {
        font-size: 18px;
        margin-left: 18px;
        margin-bottom: 0;
      }
    }
    .load-more-button {
      margin: 24px 48px;
      border-radius: 12px;
      width: calc(100% - 48px);
      .background {
        background-color: #23d2e2;
      }
    }
    .end-message {
      font-size: 18px;
      margin: 12px 0;
    }
  }
}