@import "../../../css/styles/variables";
@import "../../../css/styles/colors";
.submit-project {
  display: flex;
  flex-direction: column;
  align-items: center;
  textarea {
    height: auto;
  }
  .projects-header-container {
    width:100%;
    height: 250px;
    background-color: #fff;
    .projects-header-title-container{
      max-width: $content-max-width;
      padding: 40px 0 0 50px;
      color: $primary-color;
      @media screen and (max-width: 375px) {
        padding: 40px 0 0 30px;
      }
      .projects-title{
        margin-top: 50px;
        font-size: 48px;
        font-weight: 700;
      }
      .title-description {
        font-weight: 400;
      }
    }
  }
  .separator-container{
    width:100%;
    height: 70px;
    background-color: #fff;
    border-top: 2px solid #e2eef1;
    border-bottom: 1px solid #e2eef1;
  }
  .home-content {
    max-width: 1345px;
    width: 100%;
    margin: 40px;
    border: 1px solid #eaeaf5;
    border-width: 0 0 3px 0;
    padding-bottom: 50px;
    @media screen and (max-width: 720px) {
      margin: 40px 0;
    }
    h4 {
      margin-left: 20px;
      font-size: 16px;
      color: #8f91ac;
      font-weight: 600;
      text-transform: uppercase;
    }
    h6 {
      margin-left: 20px;
      font-size: 26px;
      font-weight: 700;
    }
  }
}

.rdw-embedded-wrapper {
  img {
    content: url("../../../css/imgs/youtube-icon.png");
    width: 17px;
  }
}

.rdw-embedded-modal-header span {
  display: none;
}

.rdw-embedded-modal-header:after {
  content: "Youtube Video Link";
}
