@import "../../../../css/styles/variables";
@import "../../../../css/styles/colors";


.challenge-page {
  font-family: $font;
  .challenge-header-container{
    width:100%;
    height: 600px;
    min-width: fit-content;
    position: relative;
    .cover-image {
      height: 100%;
      width: 100%;
      background-color: #fff;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .challenge-details-public {
      border-radius: 12px;
      background-color: #fff;
      padding: 24px;
      display: flex;
      font-family: $font;
      top: 400px;
      position: absolute;
      margin-left: 40px;
      width: calc(100% - 80px);
      min-width: fit-content;
      top: auto;
      bottom: 50px;
      @media screen and (max-width: 979px) {
        padding: 15px;
      }
      @media screen and (max-width: 900px) {
        flex-direction: column;
        align-items: center;
        margin: 40px 0 0 0;
        width: 100%;
        border-radius: 0;
      }
      @media screen and (max-width: 280px) {
        padding: 15px 0px;
      }
      .basic-challenge-info{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        @media screen and (max-width: 900px) {
          flex-direction: column;
        }
      }
      .challenge-image-container {
        display: flex;
        justify-content: center;
        margin: 8px;
        width: 120px;
        height: 90px;
        img {
          border-radius: 10px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .text {
        margin: auto 0 auto 20px;
        font-family: $font;
        @media screen and (max-width: 900px) {
          margin: 0px;
        }
        @media screen and (max-width: 414px) {
          margin-left: -3px;
        }
        @media screen and (max-width: 380px) {
          margin-left: -17px;
        }
        &.description {
          margin-right: 30px;
          @media screen and (max-width: 1400px) {
            margin-right: 10px;
          }
          @media screen and (max-width: 600px) {
            margin-bottom: 15px;
          }
        }
        .name {
          font-size: 25px;
          font-weight: 500;
          color: $primary-color;
          max-width: 300px;
          min-width: fit-content;
          img {
            height: 30px;
          }
          @media screen and (max-width: 1280px) {
            font-size: 1.9vw;
            max-width: 20vw;
          }
          @media screen and (max-width: 979px) {
            font-size: 1.7vw;
          }
          // @media screen and (max-width: 850px) {
          //   font-size: 1.6vw;
          // }
          @media screen and (max-width: 900px) {
            font-size: 17px;
            margin-top: 10px;
            text-align: center;
            max-width: none;
          }
        }
        .desc {
          font-size: 16px;
          margin: 5px 0;
          font-weight: 400;
          max-width: 300px;
          @media screen and (max-width: 1280px) {
            font-size: 1.4vw;
            max-width: 20vw;
          }
          @media screen and (max-width: 900px) {
            font-size: 14px;
            margin-bottom: 20px;
            text-align: center;
            max-width: none;
          }
        }
      }
      .challenge-specs-container {
        display: flex;
        margin-left: auto;
        margin-right: 0px;
        flex-wrap:wrap;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        @media screen and (max-width: 900px) {
          margin-left: 0px;
        }
        @media screen and (max-width: 900px) {
          margin-left: 0px;
          display: flex;
          flex-direction: column;
        }
        .challenge-spec {
          margin: 5px;
          width: fit-content;
          font-size: 20px;
          font-weight: 500;
          color: $primary-color;
          background-color: #ececec;
          border-radius: 20px;
          padding: 5px 15px;
          display: flex;
          align-items: center;
          width:fit-content;
          img {
            height: 25px;
          }
          @media screen and (max-width: 1280px) {
            img {
              height: 1.7vw;
            }
          }
          @media screen and (max-width: 979px) {
            img {
              height: 1.6vw;
            }
            font-size: 1.6vw;
          }
          @media screen and (max-width: 900px) {
            img {
              height: 17px;
            }
            font-size: 14px;
          }
          .spec-icon {
            display:inline-block;
            margin-right: 10px;
            vertical-align: middle;
            display: flex;
            align-items: center;
            @media screen and (max-width: 1280px) {
              vertical-align: baseline;
            }
          }
        }
      }
    }
  }
  .public-challenge-participation-stats-header {
    @media screen and (min-width: 600px) {
      display:none;
    }
    .separator-container{
      .info {
        display: flex;
        align-items: center;
        justify-content: center;
        .info-text {
          font-size: 14px;
          font-weight: 700;
          @media screen and (max-width: 600px) {
            font-size: 13px;
            box-shadow: 5px 5px #ecf1f9;
            padding: 5px;
            border: 1px solid #ecf1f9;
            border-radius: 10px;
            font-weight: 500;
            text-align: center;
            width: 100px;
          }
        }
      }
    }
  }
  .public-challenge-participation-stats {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 12px 0 40px;
    border-top: 1px solid #eaeaf5;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #fcfcfd;
    transition: padding 0.2s ease-in-out;
    @media screen and (max-width: 900px) {
      padding: 0 12px 0 20px;
      border-radius: 0;
    }
    @media screen and (max-width: 600px) {
      display:none;
    }
    .separator-container{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 35px 10px;
      width:100%;
      background-color: #fff;
      .info {
        display: flex;
        align-items: center;
        margin-right: 24px;
        .info-text {
          margin-left: 15px;
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }
  .body{
    padding: $content-padding;
    color: $primary-color;
    max-width: 1400px;
    @media screen and (min-width: 1400px) {
      width: 1400px;
      margin: 0 auto;
    }
    @media screen and (max-width: 900px) {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .challenge-info {
      margin: 20px 0 0 0;
      display: flex;

      @media screen and (max-width: 900px) {
        flex-direction: column;
        align-items: center;
        margin: 40px 0;
        width: 100%;
        border-radius: 0;
      }

      .challenge-info-container {
        flex: 1;
        width: 100%;
        .challenge-container {
          width: 100%;
          padding: 40px;
          background-color: #fff;
          color: $primary-color;
          border-radius: 12px;
          margin-bottom: 40px;
          display: flex;
          @media screen and (max-width: 900px) {
            border-radius: 0;
          }
          @media screen and (max-width: 600px) {
            padding: 30px;
          }
          img {
            height: 50px;
            margin-right: 20px;
            @media screen and (max-width: 600px) {
              height: 34px;
            }
          }
          .title {
            font-size: 28px;
            font-weight: 600;
            @media screen and (max-width: 1280px) {
              font-size: 24px;
            }
            @media screen and (max-width: 979px) {
              font-size: 20px;
            }
          }
          .name {
            margin-top: 15px;
            @media screen and (max-width: 979px) {
              font-size: 15px;
            }
            @media screen and (max-width: 600px) {
              left: -50px;
              position: relative;
            }
          }
        }
        .how-to-earn-challenge {
          width: 100%;
          padding: 40px;
          background-color: #fff;
          color: $primary-color;
          border-radius: 12px;
          margin-bottom: 40px;
          display: flex;
          @media screen and (max-width: 900px) {
            border-radius: 0;
          }
          @media screen and (max-width: 600px) {
            padding: 30px;;
          }
          img {
            height: 50px;
            margin-right: 20px;
            @media screen and (max-width: 600px) {
              height: 34px;
            }
          }
          .title {
            font-size: 28px;
            font-weight: 600;
            @media screen and (max-width: 1280px) {
              font-size: 24px;
            }
            @media screen and (max-width: 979px) {
              font-size: 20px;
            }
          }
          .rdw-editor-wrapper {
            @media screen and (max-width: 600px) {
              position: relative;
              left: -50px;
              width: 80vw;
            }
          }
        }
        .challenge-badges-title {
          margin: 10px;
          font-size: 22px;
        }
        .badge-list {
          margin: 0;
          overflow-y: hidden!important;
          display: grid;
          align-items: stretch;
          justify-content: center;
          grid-template-columns: repeat(auto-fit, (25%));
          @media screen and (max-width: 720px) {
            grid-template-columns: repeat(auto-fit, 50%);
          }
          @media screen and (max-width: 480px) {
            grid-template-columns: repeat(auto-fit, 100%);
          }
          > div {
            margin: 0;
            height: 100%;
          }
          a {
            display: flex;
            flex-flow: column;
            flex-grow: 1;
            height: 100%;
            .badge-item, .badge-item-sub {
              height: 95%;
              display: flex;
              flex-flow: column;
              flex-grow: 1;
              .badge-details {
                display: flex;
                flex-flow: column;
                flex-grow: 1;
                .desc {
                  flex-grow: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}

