@import "../../../css/styles/variables";
@import "../../../css/styles/colors";

.public-event-item {
  flex: 1;
  height: 100%;
  display: flex;
  flex-flow: column;

  .home-navi-sub {
    height: 100%;
    color: $primary-color;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-flow: column;
    margin: 12px;
    border-radius: 12px;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 4px 7px 12px 0 rgba(0, 0, 0, 0.10);
    transition: all 0.2s ease-in-out;

    &:hover {
      box-shadow: 0px 0px 8px 0px $light-gray-color;
      transform: scale(1.03);
    }

    .workshop-image {
      display: flex;
      justify-content: center;
      align-self: flex-start;

      img {
        width: 100%;
        aspect-ratio: 1.5;
        object-fit: cover;
      }
    }

    .text {
      text-align: left;
      margin: -36px 8px 16px 8px;
      padding: 12px;
      display: flex;
      flex-flow: column;
      text-align: center;
      height: 100%;
      &.workshop-desc {
        height: 100%;
        cursor: default;
      }

      .description {
        padding-top: 10px;
        text-align: left;
        font-size: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align: left;
      }

      .event-title {
        margin-bottom: 0;
        font-size: 20px;
        font-weight: 700;
        height: 52px;
      }

      .workshop-desc {
        height: 100%;
        cursor: default;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
  
        p {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .button-element-container {
          display: flex;
          flex-direction: column;
  
          .gap-fill {
            flex-grow: 1;
          }
  
          .more-button {
            border-radius: 12px;
          }
        }
      }
    }

    .date-sticker {
      position: relative;
      left: 5%;
      bottom: 36px;
      border-radius: 12px;
      width: 44px;
      z-index: 2;

      .date-sticker-day {
        height: 36px;
        background-color: rgba(115, 83, 255, 0.5);
        color: #ffffff;
        border-radius: 12px 12px 0 0;
        text-align: center;
        padding: 6px 0 6px 0;
        font-size: 20px;
        font-weight: 700;
      }

      .date-sticker-month {
        height: 24px;
        padding: 4px 0;
        text-align: center;
        background-color: $secondary-color;
        border-radius: 0 0 12px 12px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
}