
.builder-pal-chat-container {
  position: relative;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94,92,154,.06);
  display: flex;
  background-color: #fff;
  flex-direction: row;
  max-height: 718px;
  height: 100%;
  .chat-questions {
    flex: 3;
    padding: 20px 20px 0 20px;
    border-right: 1px solid #ecf1f9;
    height: 100%;
    background-color: #fff;
    border-radius: 12px;
    .guiding-title {
      font-size: 21px;
      font-weight: 700;
      padding-bottom: 20px;
      padding-top: 20px;
      line-height: 1em;
      margin: 0;
    }
    .guiding-question {
      background-color: #23d2e2;
      border: 0;
      box-shadow: 4px 7px 12px 0 rgba(35,210,226,.2);
      height: 54px;
      line-height: 54px;
      margin-bottom: 20px;
      border-radius: 10px;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-size: 0.9em;
      font-weight: 700;
      text-align: center;
      transition: background-color .2s ease-in-out,color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
      width: 100%;
      &:hover {
        opacity: 0.7;
      }
      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
    }
  }
  .hide-section {
    @media screen and (max-width: 979px) {
      display: none;
    }
  }
  .chat-section {
    flex: 4;
    border-radius: 12px;
    background-color: #fff;
    @media screen and (max-width: 979px) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.builder-pal-chat-interface {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .chat-widget-header {
    position: relative;
    border-bottom: 1px solid #ecf1f9;
    padding: 28px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 430px) {
      padding: 28px 12px;
    }
    .user-status {
      position: relative;
      min-height: 44px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .chat-back-button {
        @media screen and (max-width: 979px) {
          display: flex;
        }
        position: relative;
        border: 0;
        background-color: #fff;
        left: -12px;
        height: 40px;
        width: 40px;
        cursor: pointer;
        display: none;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
        svg {
          width: 30px;
          height: 30px;
          fill: #3e3f5ea1;
        }
      }
      .user-status-avatar {
        position: relative;
        margin-right: 12px;
        height: 36px;
        width: 36px;
        .online-dot {
          position: absolute;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: #1df377;
          left: -4px;
        }
        .image {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .user-status-title {
        color: #3e3f5e;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        margin: 0;
      }
      .user-status-tag {
        background-color: #1df377;
        border-radius: 200px;
        color: #fff;
        display: inline-block;
        font-size: 13px;
        font-weight: 700;
        height: 20px;
        line-height: 20px;
        padding: 0 8px;
        position: relative;
        text-transform: uppercase;
        margin: 0;
      }
    }
    .top-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      .new-chat {
        width: 100px;
        border-radius: 8px;
        .background {
          background-color: #23d2e2;
        }
        button {
          padding: 0;
        }
      }
      .home {
        width: 36px;
        height: 36px;
        margin-right: 18px;
        .background {
          background-color: #23d2e2;
        }
        button {
          width: 100%;
          height: 100%;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            fill: #fff;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  .messages {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    padding: 35px 0 4px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    .infinite-scroll-component__outerdiv {
      width: 100%;
    }
    .message-loader {
      margin-bottom: 24px;
    }
    .assistant-message-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;
      padding: 0 28px;
      .avatar {
        width: 24px;
        height: 24px;
        .image {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .chat-box {
        margin-left: 18px;
        max-width: 80%;
        background-color: #f5f5fa;
        border-radius: 12px;
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        padding: 12px;
        border-top-left-radius: 0;
      }
    }
    .user-message-container {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: 16px;
      padding: 0 28px;
      .avatar {
        width: 32px;
        height: 32px;
        .image {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          object-fit: contain;
        }
      }
      .chat-box {
        margin-right: 18px;
        max-width: 80%;
        background-color: #615dfa;
        color: #fff;
        border-radius: 12px;
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        padding: 12px;
        border-top-right-radius: 0;
        border: 0;
      }
      .audio-button {
        cursor: pointer;
        text-align: left;
        padding-left: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .sound-gif {
          height: 20px;
        }
        &:hover{
          box-shadow: inset 0 0 250px 0 rgba(0,0,0,.1);
        }
        &:active{
          box-shadow: inset 0 0 250px 0 rgba(0,0,0,.2);
        }
      }
    }
  }
  .suggestions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 8px 28px 0;
    min-height: 44px;
    flex-wrap: wrap;
    .suggestion-loading {
      display: flex;
      flex-direction: row;
      align-items: center;
      @keyframes fade {
        0% { opacity: 1; }
        50% { opacity: 0.3; }
        100% { opacity: 1; }
      }
      animation: fade 3s ease-in-out infinite;
      .suggestion-loading-text {
        font-size: 16px;
        margin-left: 12px;
      }
    }
    .suggestion {
      height: 28px;
      margin-right: 12px;
      margin-bottom: 8px;
      padding: 4px 8px;
      border-radius: 14px;
      border: #23d2e2 solid 1px;
      font-weight: 600;
      font-size: 15px;
      background-color: #fff;
      color: #23d2e2;
      cursor: pointer;
      &:hover {
        background-color: #23d2e2;
        color: #fff;
        opacity: 0.5;
      }
    }
  }
  .user-input {
    width: 100%;
    height: 108px;
    padding: 10px 24px 18px;
    display: flex;
    flex-direction: row;
    .text-input {
      flex: 1;
      height: 50px;
      padding: 0 18px;
      background-color: #fff;
      border: 1px solid #dedeea;
      transition: border-color .2s ease-in-out;
      border-radius: 12px;
      font-size: 14px;
      font-weight: 700;
      margin-right: 12px;
      @media screen and (max-width: 500px) {
        height: 36px;
        margin-right: 6px;
      }
    }
    .record-bar {
      flex: 1;
      height: 48px;
      border-radius: 12px;
      @media screen and (max-width: 500px) {
        height: 36px;
        margin-right: 6px;
      }
      .background {
        background-color: #23d2e2
      }
      button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%;
        svg {
          width: 20px;
          height: 20px;
          fill: #fff;
          margin-right: 12px;
        }
      }
    }
    .interactive-buttons {
      display: flex;
      flex-direction: row;
      .interactive-button {
        margin-left: 6px;
        width: 64px;
        height: 48px;
        border-radius: 10px;
        .background {
          background-color: #23d2e2;
        }
        button {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 24px;
            height: 24px;
            fill: #fff;
          }
        }

        @media screen and (max-width: 500px) {
          height: 36px;
          width: 36px;
          button {
            padding: 0;
            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }

  // dot animation
  .dot-flashing {
    position: relative;
    margin: 6px 15px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -12px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 12px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }
  
  @keyframes dot-flashing {
    0% {
      background-color: #9880ff;
    }
    50%, 100% {
      background-color: rgba(152, 128, 255, 0.2);
    }
  }
}