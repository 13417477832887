.str-chat__attachment-list {
  align-items: flex-start;

  .str-chat__message-attachment {
    max-width: 100%;
    .str-chat__modal {
      .str-chat__modal__close-button {
        inset-block-start: 18px;
        inset-inline-end: 18px;
        @media only screen and (max-width: 979px) {
          inset-block-start: 64px;
          inset-inline-end: 6px;
        }
      }
      .str-chat__modal__inner {
        width: 80%;
        height: 80%;
        .image-gallery, .image-gallery-content, .image-gallery-slide-wrapper, .image-gallery-slides, .image-gallery-slide, .image-gallery-image, .image-gallery-swipe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .str-chat__message-attachment--card--actions {
    overflow: hidden;
  }

  .str-chat__message-attachment-audio-widget {
    flex: 1 1 var(--str-chat__attachment-max-width);
    max-width: var(--str-chat__attachment-max-width);
  }

  .str-chat__message-attachment-card--text {
    display: none;
  }
}


