@import "../../../css/styles/variables";
@import "../../../css/styles/colors";

.notification-box-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 850px) {
    flex-direction: column;
  }
  .notification-box {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin-bottom: 20px;
    margin: 10px;
    padding: 18px 28px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 4px 7px 12px 0 rgba(0, 0, 0, 0.10);
    color: $primary-color;
    width: 30%;
    transition: all 0.3s ease-out;
    &:hover{
      transform: scale(1.01);
    }
    @media screen and (max-width: 850px) {
      width: 100%;
    }
    @media screen and (max-width: 500px) {
      margin-left: 0px;
    }
    &.hide{
      display: none;
    }
    &.late,
    &.cancelled,
    &.rejected {
      background-color: #fcfcfd;
      box-shadow: 4px 7px 12px 0 rgba(143, 145, 172, 0.1);
    }
    .user-status {
      align-items: center;
      .booking-item-header-container{
        display: flex;
        justify-content: space-between;
        .user-status-title {
          margin-right: 20px;
          font-size: 18px;
          font-weight: 600;
          @media screen and (max-width: 850px) {
            font-size: 15px;
          }
        }
        .action-request-list {
          width: 25px;
          height: 25px;
          padding: 2px 8px;
          cursor: pointer;
          fill: #fd4350;
          border-radius: 80px;
          width: fit-content;
          transition: all 0.3s ease-out;
          &:hover{
            fill: #ff757e;
            transform: scale(1.01);
            background-color: #ddd;
          }
          .action-request-icon{
            width: 20px;
            height: 20px;
            @media screen and (max-width: 850px) {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
      .user-status-text {
        margin: 10px 0;
        font-size: 14px;
        font-weight: 400;
        @media screen and (max-width: 850px) {
          font-size: 11px;
        }
      }
    }
  }
}

.booking-status-container{
  display: flex;
  margin-top: 20px;
  .booking-status {
  font-weight: 400;
  border-radius: 80px;
  width: fit-content;
  min-width: 80px;
  text-align: center;
  padding: 5px;
  font-size: 12px;
  color: #fff;
  background-color: $secondary-color;
  @media screen and (max-width: 850px) {
    font-size: 11px;
  }
  &.approved {
    background-color: #2bae66;
    color: #fff;
  }
  &.rejected {
    color: #fd4350;
    font-weight: 700;
  }
  &.cancelled {
    color: #ffa500;
    font-weight: 700;
  }
  &.late {
    color: #fd4350;
    font-weight: 400;
  }
  }
}

.show-more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  button {
    background: none;
    border: none;
    font-weight: 600;
    cursor: pointer; 
    transition: all 0.2s ease-in-out;
  }
  button:hover {
    color: gray;
  }
}