@import '../../../../css/styles/colors';
@import '../../../../css/styles/variables';

.member-signup {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 80px 10% 5% 10%;
  @media screen and (max-width: 780px) {
    padding: 80px 20px 20px 20px;
  }
  .member-signup-info {
    z-index: 1;
    width: 100%;
    background-color: #fff;
    border-radius: 20px;
    padding: 40px;
    position: relative;
    height: fit-content;
    .sign-up-description{
      font-size: 18px;
      font-weight: 400;
      color: #fff;
      margin: -20px 0 30px 30%;
      padding-right: 10%;
      text-align: right;
      @media screen and (max-width: 780px) {
          font-size: 15px;
          text-align: right;
          padding-right: 20px;
      }
    }
    .section-title-top{
      font-size: 25px;
      font-weight: 600;
    }
    .section-title{
      font-size: 25px;
      font-weight: 600;
      padding-top: 40px;
    }
    .sign-up-form{
      .text-input{
        background-color:$background-color;
        font-weight: 500;
        font-size: 15px;
        border-radius: 20px;
        border: 1px solid #dcdcdc;
        box-shadow: inset 0 0 0 0 transparent;
        height: 40px;
        line-height: 40px;
        margin: 0 0 12px;
        outline: none;
        padding: 0 12px;
        position: relative;
        width: 100%;
      }
      .dob-input{
        display: flex;
        flex-direction: row;
        justify-self: flex-start;
        select {
          width: 20%;
          min-width: 80px;
          background-color:$background-color;
          font-weight: 500;
          font-size: 15px;
          border-radius: 20px;
          margin: 0 20px 12px 0;
          height: 40px;
          text-indent: 18px;
        }
        &:last-child {
          margin: 0;
        }
      }
      label{
        font-size: 15px;
        font-weight: 500;
        color: rgb(130, 130, 130);
        padding-bottom: 5px;
      }
      .add-second-guardian {
          max-width: 250px;
          margin-bottom: 25px;
          // margin: 20px;
          height: 40px;
          border-radius: 20px;
          background-color: #7353ff;
          color: white;
          font-size: 16px;
          width: 100%;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          input {
            display: none;
          }
      }
      .button-element.submit-form {
        border-radius: 20px;
        margin: 50px auto 0;
        min-width: 200px;
        width: 50%;
        .button-status-image {
          display: none;
        }
      }
      .subsection-title {
        font-size: 18px;
        &.second-guardian {
          padding-top: 20px;
        }
      }
      .password-input .show-password {
        top: 0;
        right: 0;
        border-radius: 50px;
        width: 40px;
        height: 40px;
        background-color: transparent;
      }
      .success-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 40px 0;
        color: $primary-color;
       
        .logo-image {
          user-select: none;
          -webkit-user-select: none;
          -webkit-user-drag: none;
          width: 100%;
          max-width: 318px;
        }
        .email-sent {
          margin: 30px;
          font-size: 32px;
          text-align: center;
        }
        .check-email {
          font-size: 18px;
          color: black;
          font-weight: 600;
          max-width: 800px;
          text-align: center;
        }
      }
    }
  }
  .back-button {
    height: 48px;
    width: 48px;
    top: 24px;
    left: 24px;
    position: absolute;
    border-radius: 2px;
    padding: 8px;
    cursor: pointer;
    svg{
      height: 100%;
      width: 100%;
      .real-path{
        fill: $primary-color;
      }
    }
    z-index: 150;
    &:hover{
      box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.1);
    }
    &:active{
      box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.2);
    }
  }
}