@import "../../../../css/styles/variables";
@import "../../../../css/styles/colors";

.timeline-information{
    .widget-box-settings{
        display: flex;
        position: absolute;
        top: -4px;
        right: 0;
        z-index: 9999;
        .post-settings-wrap {
            position: relative;
            padding-left: 20px;
            transition: all 0.2s ease-in-out;
            &:hover {
              .simple-dropdown {
                opacity: 1;
                visibility: visible;
                transform: translate(0px, 0px);
              }
              .post-settings {
                .post-settings-icon {
                  opacity: 1;
                }
                .post-settings-text {
                  color:$primary-color;
                }
              }
            }
            .post-settings {
              display: flex;
              align-items: center;
              width: auto;
              height: 30px;
              cursor: pointer;
              .post-settings-icon {
                fill: #000;
                opacity: 0.4;
                width: 22px;
                height: 22px;
                margin-left: 5px;
              }
              .post-settings-text{
                color:#888;
                margin: auto;
                @media screen and (max-width: 979px) {
                  display: none;
                }
              }
            }
            .simple-dropdown {
              position: absolute;
              z-index: 9999;
              top: 30px;
              right: 9px;
              opacity: 0;
              visibility: hidden;
              transform: translate(0px, -20px);
              width: 140px;
              padding: 10px 0;
              border-radius: 12px;
              background-color: #fff;
              box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.12);
              transition: transform 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;
              a:hover {
                text-decoration: none;
              }
              .simple-dropdown-link {
                padding: 8px 16px;
                font-size: 0.75rem;
                font-weight: 700;
                cursor: pointer;
                margin: 0;
                transition: all 0.2s ease-in-out;
                &:hover {
                  padding-left: 20px;
                  color: $secondary-color;
                }
              }
            }
        }
    }
    .timeline-information-date {
        margin-top: 6px;
        color: $primary-color;
        font-weight: 500;
        font-size: 18px;
        text-transform: uppercase;
      }
      .project-image {
        position: relative;
        display: flex;
        justify-content: center;
        .project-image-arrow{
          transition: all 0.2s ease-in-out;
          .icon-arrow{
            transition: all 0.2s ease-in-out;
          }
        }
        &:hover {
          .project-image-arrow {
            padding: 12px 18px;
            .icon-arrow {
              width: 40px;
            }
          }
        }
        .profile-image {
          min-height: 400px;
          max-height: 100%;
          max-width: 100%;
          height: 100%;
          width: 100%;
          box-shadow: 0px 3px 8px 0px rgbA(0, 0, 0, 0.1);
          border-radius: 12px;
        }
        .project-image-arrow {
          position: absolute;
          top: 50%;
          transform: translate(0%, -50%);
          background-color: #222;
          opacity: 0.8;
          padding: 12px 0;
          border: none;
          cursor: pointer;
          border-radius: 5px;
          &.back {
            left: 0;
          }
          &.forward {
            right: 0;
          }
          .icon-arrow {
            width: 0;
            height: 40px;
            fill: #fff;
          }
        }
        .carousel-item-project-media {
          width: 100%!important;
        }
      }
      .project-files {
        .timeline-information-title {
            font-size: 18px;
            font-weight: 700;
            margin: 0;
          }
        > ul {
          margin: 0;
          > li {
            margin: 0;
            a {
              display: block;
            }
          }
        }
      }
    
}