.builder-pal-project {
  color: #3e3f5e;
  font-family: Rajdhani,sans-serif;
  line-height: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  .builder-pal {
    max-width: 1280px;
    margin-top: 32px;
    width: 100%;
    padding: 18px;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 1.6rem;
      font-weight: 700;
      margin-top: 8px;
    }
    .builder-pal-project-container {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-top: 36px;
      .project-prompt-container {
        width: 100%;
        max-width: 324px;
        @media screen and (max-width: 979px) {
          display: none;
        }
        .project-prompt {
          border-radius: 20px;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 24px;
          .guiding-title {
            font-size: 21px;
            font-weight: 700;
            margin: 24px 0 9px 0;
            line-height: 24px;
          }
          .prompt-button {
            height: 48px;
            margin: 9px 0;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .background {
              background-color: #23d2e2;
            }
          }
          .guiding-hint {
            margin: 18px 0 0 0;
          }
        }
        .home {
          position: absolute;
          top: 28px;
          left: 28px;
          width: 36px;
          height: 36px;
          margin-right: 18px;
          .background {
            background-color: #23d2e2;
          }
          button {
            width: 100%;
            height: 100%;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              fill: #fff;
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
    .project-detail {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      .project-card {
        position: relative;
        width: 100%;
        max-width: 544px;
        border-radius: 12px;
        background-color: #fff;
        box-shadow: 0 0 40px 0 rgba(94,92,154,.06);
        overflow: hidden;
        margin: 18px 0;
        &:first-child {
          margin-top: 0;
        }
        .image {
          width: 100%;
        }
        .project-card-detail {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 12px 48px;
          @media screen and (max-width: 600px) {
            padding: 12px;
          }
          .title {
            font-size: 28px;
            line-height: 30px;
            font-weight: 700;
            text-align: center;
            margin: 24px 0 0 0;
          }
          .description {
            font-size: 18px;
            color: #adafca;
            font-weight: 700;
            margin-top: 18px;
            text-align: center;
            line-height: 20px;
          }
          .project-card-info {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            margin: 12px 4px;
            .separator {
              border-right: 2px solid #eee;
              height: 18px;
              margin-bottom: 24px;
            }
            .info-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 8px 18px;
              flex: 1;
              .info-text {
                font-size: 20px;
                font-weight: 700;
                text-align: center;
                text-transform: uppercase;
                margin: 0;
                width: 120px;
                @media screen and (max-width: 500px) {
                  width: 64px;
                  font-size: 15px;
                }
              }
              .info-title {
                font-weight: 16px;
                color: #adafca;
                font-weight: 700;
                text-align: center;
                text-transform: uppercase;
                margin: 12px 0 0 0;
                @media screen and (max-width: 500px) {
                  font-size: 12px;
                }
              }
            }
          }
        }
        .project-resource {
          width: 100%;
          padding: 36px;
          .title {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 24px;
          }
          .checkbox-line {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 12px;
            input {
              margin-right: 12px;
            }
            .resource {
              margin: 0;
              font-size: 18px;
            }
          }
          .tags {
            margin: 36px 0 0 0;
            font-size: 18px;
            font-weight: 700;
          }
        }
        .project-steps {
          width: 100%;
          padding: 36px;
          .title {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 30px;
          }
          .timeline {
            padding-bottom: 16px;
            padding-left: 28px;
            position: relative;
            cursor: pointer;
            .step {
              font-weight: 600;
              font-size: 16px;
            }
            &::before {
              background-color: #eaeaf5;
              content: "";
              height: 100%;
              left: 6px;
              position: absolute;
              top: 2px;
              width: 1px;
              display: block;
              box-sizing: border-box;
            }
            &::after {
              border: 4px solid #23d2e2;
              background-color: #fff;
              border-radius: 50%;
              content: "";
              height: 13px;
              left: 0;
              position: absolute;
              top: 2px;
              width: 13px;
              display: block;
              box-sizing: border-box;
            }
            &:last-child {
              padding-bottom: 0;
              &::before {
                display: none;
              }
            }
          }
        }
        .project-step-detail {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 12px 36px;
          min-height: 250px;
          .title {
            font-size: 28px;
            line-height: 32px;
            font-weight: 700;
            margin: 24px 0 0 0;
          }
          .description {
            font-size: 20px;
            color: #aaa;
            font-weight: 600;
            margin-top: 18px;
            line-height: 24px;
          }
        }
        .like-button {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          top: 16px;
          right: 16px;
          display: flex;
          border-radius: 12px;
          transition: all 0.2s ease-in-out;
          background-color: #fff;
          padding: 6px 12px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          width: 174px;
          cursor: pointer;
          .heart-button {
            width: 26px;
            height: 26px;
            margin-right: 6px;
            path {
              stroke: rgb(248,48,95);
            }
          }
          .text {
            font-size: 15px;
            color: #666;
          }
          &:hover {
            background-color: #f2f2f2;
          }
        }
        .active {
          background-color: rgb(248,48,95);
          svg {
            fill: #fff;
          }
          .text {
            color: #fff;
          }
          &:hover {
            background-color: rgb(223, 46, 87);
          }
        }
        .back-button {
          @media screen and (max-width: 979px) {
            display: flex;
          }
          display: none;
          position: absolute;
          top: 16px;
          left: 16px;
          width: 36px;
          height: 36px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          .background {
            background-color: #23d2e2;
          }
          button {
            width: 100%;
            height: 100%;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              fill: #fff;
              width: 24px;
              height: 24px;
            }
          }
        }
      }
      .next-button {
        margin: 4px 0;
        border-radius: 12px;
        width: 100%;
        max-width: 544px;
        .background {
          background-color: #23d2e2;
        }
      }
      .nav-buttons {
        margin: 12px 0;
        max-width: 544px;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        .nav-button {
          flex: 1;
          border-radius: 8px;
          button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            svg {
              fill: #fff;
              width: 20px;
              height: 20px;
            }
            p {
              margin: 0;
            }
          }
          .background {
            background-color: #23d2e2;
          }
          &:first-child {
            margin-right: 18px;
          }
        }
        .home {
          .background {
            background-color: #7353ff;
          }
        }
      }
      .loader-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 24px;
        opacity: 0.8;
        height: 40px;
        .loader-text {
          font-size: 18px;
          margin-left: 18px;
          margin-bottom: 0;
        }
      }
    }
  }
  .chat-bubble {
    position: fixed;
    z-index: 999;
    bottom: 48px;
    right: 48px;
    border-radius: 36px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #23d2e2;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border: 0;
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
      fill: #fff;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .chat-bot {
    position: fixed;
    z-index: 1000;
    bottom: 36px;
    right: 36px;
    width: 100%;
    height: 640px;
    max-width: 464px;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    transform: translateX(128%);
    transition: transform 0.5s ease-in-out;
    .close-button {
      position: absolute;
      top: 12px;
      right: 12px;
      border: 0;
      background-color: #fff;
      width: 36px;
      height: 36px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: #eee;
      }
    }
    @media screen and (max-width: 545px) {
      right: 12px;
    }
    @media screen and (max-width: 464px) {
      right: 0;
    }
  }
  .active {
    transform: translateX(0);
  }
}