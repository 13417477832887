.builder-pal-home {
  color: #3e3f5e;
  font-family: Rajdhani,sans-serif;
  line-height: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;

  .builder-pal {
    max-width: 1280px;
    margin-top: 32px;
    width: 100%;
    padding: 18px;
    display: flex;
  flex-direction: column;
    .title {
      font-size: 1.6rem;
      font-weight: 700;
      margin-top: 8px;
    }
    .builder-pal-home-container {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 100%;
      margin-top: 36px;

      @media screen and (max-width: 979px) {
        flex-direction: column;
        align-items: center;
        padding: 0 24px;
      }
      .builder-pal-chats-container {
        width: 100%;
        max-width: 324px;
        height: 100%;
        background-color: #fff;
        box-shadow: 0 0 40px 0 rgba(94,92,154,.06);
        border-radius: 12px;
        margin-right: 18px;
        padding: 36px 18px;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 979px) {
          max-width: none;
          margin-right: 0;
        }
        .new-chat-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 0 12px;
          .user-status {
            position: relative;
            min-height: 44px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .chat-back-button {
              @media screen and (max-width: 979px) {
                display: flex;
              }
              position: relative;
              border: 0;
              background-color: #fff;
              left: -12px;
              margin-right: 6px;
              height: 40px;
              width: 40px;
              cursor: pointer;
              display: none;
              justify-content: center;
              align-items: center;
              &:hover {
                background-color: rgba(0, 0, 0, 0.1);
              }
              svg {
                width: 30px;
                height: 30px;
                fill: #3e3f5ea1;
              }
            }
            .user-status-avatar {
              position: relative;
              margin-right: 12px;
              height: 36px;
              width: 36px;
              .online-dot {
                position: absolute;
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background-color: #1df377;
                left: -4px;
              }
              .image {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
            .user-status-title {
              color: #3e3f5e;
              font-size: 16px;
              font-weight: 700;
              line-height: 26px;
              margin: 0;
            }
            .user-status-tag {
              background-color: #1df377;
              border-radius: 200px;
              color: #fff;
              display: inline-block;
              font-size: 13px;
              font-weight: 700;
              height: 20px;
              line-height: 20px;
              padding: 0 8px;
              position: relative;
              text-transform: uppercase;
              margin: 0;
            }
          }
          .new-chat {
            width: 100px;
            border-radius: 8px;
            button {
              padding: 0;
            }
          }
        }
        .builder-pal-chat-list {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 36px 0 0;
          .chat-list-title {
            padding: 0 12px;
            color: #999;
            margin: 0 0 12px;
          }
          .chat-item {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 12px;
            border-radius: 12px;
            cursor: pointer;
            &:hover {
              background-color: #f2f2f2;
              text-decoration: none;
            }
            .title-text {
              width: 100%;
              margin: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      .builder-pal-projects-container {
        flex: 1;
        margin: 0 0 0 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media screen and (max-width: 979px) {
          margin: 0;
        }
        .project-list-title {
          padding: 0 12px;
          margin: 18px 0;
          font-size: 24px;
          @media screen and (max-width: 979px) {
            margin: 36px 0 0;
          }
        }
        .project-list {
          width: 100%;
          display: grid;
          align-items: stretch;
          justify-content: space-evenly;
          grid-template-columns: repeat(auto-fit, 50%);
          @media screen and (max-width: 979px) {
            grid-template-columns: repeat(auto-fit, 100%);
            margin: 20px 0;
          }
          > div {
            margin: 0;
            height: 100%;
          }
          a {
            display: flex;
            flex-flow: column;
            flex-grow: 1;
            height: 100%;
            @media screen and (max-width: 979px) {
              padding: 0;
            }
          }
        }
        .loader-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin: 24px;
          opacity: 0.8;
          height: 40px;
          width: 100%;
          .loader-text {
            font-size: 18px;
            margin-left: 18px;
            margin-bottom: 0;
          }
        }
        .load-more-button {
          margin: 24px;
          border-radius: 12px;
          width: calc(100% - 48px);
          .background {
            background-color: #23d2e2;
          }
        }
        .end-message {
          font-size: 18px;
          margin: 12px 0;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}