@import "../../../../css/styles/colors";
@import "../../../../css/styles/variables";

.home-builderpal-container {
  width: 100%;
  background-image: url("../../../../css/imgs/background.jpg");
  background-size: cover; 
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 18px;
  .inner-container {
    width: 100%;
    max-width: 1080px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: $mobile-width) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .title-container {
      width: 400px;
      height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 24px;
      border-radius: 18px;
      color: #ddd;
      @media screen and (max-width: $mobile-width) {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        width: 100%;
        color: #3e3f5e;
        background-color: #fff;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      }
      h6, p {
        margin-bottom: 24px;
      }
      .title {
        text-align: center;
        font-size: 36px;
      }
      .subtitle {
        text-align: center;
        font-size: 22px;
        font-weight: 700;
      }
      .next-button {
        width: 200px;
        .background {
          background-color: #23d2e2;
        }
        button {
          font-size: 20px;
        }
      }
    }
  }
}