
// Color palet
// 1 - #30C5FF - SPIRO DISCO BALL
// 2 - #2A2D34 - GUNMETAL
// 3 - #5C946E - RUSSIAN GREEN
// 4 - #80C2AF - CAMBRIDGE BLUE
// 5 - #A0DDE6 - NON-PHOTO BLUE

$primary-color: #183b56;
$primary-color-hover: #6d7bab;
$secondary-color: #7353ff;
$secondary-color-hover: #8a71fd ;
$accent-color: #A0DDE6;
$background-color: #ecf1f9;
$box-shadow-color: #192c4b05;
$light-gray-color: rgba(200,200,200,1);
$gray-screen-background-color: rgba(0,0,0,.65);