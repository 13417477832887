.preloader {
  $colors: #23d2e2, #89cff0, #4682b4, #0f52ba, #000080;
  display: flex;
  justify-content: center;
  align-items: center;

  .preloader-dot {
    position: relative;
    width: 16px;
    height: 16px;
    margin: 8px;
    border-radius: 50%;

    &::before {
      position: absolute;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      background: inherit;
      border-radius: inherit;
      animation: wave 2s ease-out infinite;
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        background: nth($colors, $i);

        &::before {
          animation-delay: $i * 0.2s;
        }
      }
    }
  }
}

@keyframes wave {
  50%,
  75% {
    transform: scale(2);
  }

  80%,
  100% {
    opacity: 0;
  }
}
