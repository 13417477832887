.step-four {
  margin: 20px 20px 20px 0px;
  .text {
    font-family: Rajdhani, sans-serif;
    .name {
      color: red;
    }
  }
  .heading {
    font-size: 36px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .subheading {
    font-size: 24px;
  }
  .viviboom-text {
    font-size: 48px;
    font-family: Titillium Web, sans-serif;
    color: #000;
  }
  .cover-image {
    // top: 0px;
    // left: 0px;
    margin: 20px auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 560px;
    border-radius: 20px;
    img {
      //   width: 100%;
      height: 200px;
    }
  }
  .welcome-modal-image-buttons {
    display: block;
    margin: 20px 20px 32px;
    align-items: center;
    text-align: center;
    label {
      width: 160px;
      margin: 16px auto;
      background-color: rgb(0, 0, 0) !important;
      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        &:hover {
          box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
        }
      }

      input {
        display: none;
      }
    }
    .button {
      width: 160px;
      margin: 16px auto;
      background-color: rgb(82, 219, 64);
    }
  }
  .cropper-cover {
    width: 1120px;
    height: 400px;
    margin: auto;
    background-color: white;
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translate(-50%, 0);
    z-index: 1000002;
  }
  .button-element {
    margin-top: 40px;
  }
  .dimmed-background {
    background: #000;
    opacity: 0.5;
    position: fixed;
    left: -100% !important;
    top: -100% !important;
    height:300vh;
    width:300vw;
    overflow: auto;
    z-index: 1000001; /* may not be necessary */
  }
}

@media screen and (max-width: 979px) {
  .step-three {
    .heading {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

@media screen and (max-width: 480px) {
  .step-four {
    margin: 24px 12px 12px 12px;

    .heading {
      font-size: 28px;
    }
    .subheading {
      font-size: 16px;
    }
    .viviboom-text {
      font-size: 36px;
    }
  }
}
