@import "../../../sass/color";
@import "../../../sass/color-theme";
@import "../../../sass/font";
@import "../../../css/styles/colors";

.password-input {
  position: relative;
  input[type="text"],
  input[type="password"] {
    // border-top-right-radius: 0px;
    // border-bottom-right-radius: 0px;
    position: static;
    width: 356.01px;
  }
  &.active {
    label {
      background-color: $color-box-background;
      padding: 0 6px;
      font-size: $fs-12;
      top: -6px;
      left: 12px;
    }
  }
  .show-password {
    background-color: #fff;
    top: 4px;
    right: 8px;
    position: absolute;
    // border-left: 0px;
    // border-radius: 12px;
    // border-top-left-radius: 0px;
    // border-bottom-left-radius: 0px;
    border: 0px;
    width: 45px;
    height:45px;
    cursor: pointer;
    svg {
      fill: $primary-color;
      margin: 20%;
      width: 60%;
      height: 60%;
    }
    &:hover {
      z-index: 10;
    }
  }
}
