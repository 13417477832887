@import "../../../sass/color";
@import "../../../css/styles/colors";
@import "../../../css/styles/variables";
.home-page {
  margin: 0 auto 60px;
  max-width: $content-max-width;
  padding: $content-padding;
  @media screen and (max-width: $mobile-width) {
    padding: 88px 12px 12px;
  }
  .home-user-info {
    height: 240px;
    margin: 40px 0;
    border-radius: 12px;
    background-color: #fff;
    padding: 20px;
  }
  .home-content {
    margin: 0 0 50px;
    border: 1px solid #eaeaf5;
    border-width: 0 0 3px 0;
    padding-bottom: 50px;
    h1 {
      margin: 32px 0 32px 0;
      font-size: 32px;
    }
    h4 {
      margin: 0;
      font-size: 16px;
      color: #8f91ac;
      font-weight: 600;
      text-transform: uppercase;
    }
    h6 {
      font-size: 26px;
      font-weight: 700;
    }
    .no-workshop {
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      border-radius: 12px;
      margin: 30px 0 20px 0;
      padding: 12px;
      min-height: 180px;
      h1 {
        text-align: center;
        @media screen and (max-width: $mobile-width) {
          font-size: 24px;
        }
      }
      // dot animation
      .dot-flashing {
        position: relative;
        margin: 6px 15px;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-color: #9880ff;
        color: #9880ff;
        animation: dot-flashing 1s infinite linear alternate;
        animation-delay: 0.5s;
      }
      .dot-flashing::before, .dot-flashing::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
      }
      .dot-flashing::before {
        left: -24px;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-color: #9880ff;
        color: #9880ff;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 0s;
      }
      .dot-flashing::after {
        left: 24px;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-color: #9880ff;
        color: #9880ff;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 1s;
      }
      
      @keyframes dot-flashing {
        0% {
          background-color: #9880ff;
        }
        50%, 100% {
          background-color: rgba(152, 128, 255, 0.2);
        }
      }
    }
    .carousel-item {
      img {
        border-radius: 12px;
      }
    }
    .iframe-wrap {
      width: 100%;
      padding-top: 56.25%;
      position: relative;
      margin-bottom: 20px;
      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 12px;
      }
    }
    .members-container {
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(auto-fit, 33%);
      @media screen and (max-width: 979px) {
        grid-template-columns: repeat(auto-fit, 50%);
      }
      @media screen and (max-width: 630px) {
        grid-template-columns: repeat(auto-fit, 100%);
      }
    }
    .button-container {
      position: relative;
      bottom: 0;
      padding: 20px;
      width: 100%;
      text-align: center;
      a {
        line-height: 52px;
        height: 52px;
        background-color: $secondary-color;
        border-radius: 12px;
        font-weight: 700;
        width: 200px;
        color: #ffffff;
        margin: auto;
      }
    }
    .carousel-ctn {
      padding-bottom: 30px;
    }
    .home-options {
      margin: 0;
      display: grid;
      align-items: stretch;
      justify-content: center;
      grid-template-columns: repeat(auto-fit, (33%));
      @media screen and (max-width: 979px) {
        grid-template-columns: repeat(auto-fit, 50%);
      }
      @media screen and (max-width: 630px) {
        grid-template-columns: repeat(auto-fit, 100%);
      }

      &.home-2 {
        grid-template-columns: repeat(auto-fit, (50%));
        @media screen and (max-width: 630px) {
          grid-template-columns: repeat(auto-fit, 100%);
        }
      }

      > li {
        margin: 0;
        height: 100%;
        display: flex;
        flex-flow: column;
        .badge-item {
          display: flex;
          flex-flow: column;
          flex-grow: 1;
          .badge-item-sub {
            // height: 95%;
            display: flex;
            flex-flow: column;
            flex-grow: 1;
            .badge-details {
              display: flex;
              flex-flow: column;
              flex-grow: 1;
              .desc {
                flex-grow: 1;
              }
            }
          }
        }
        .project-item {
          display: flex;
          flex-flow: column;
          flex-grow: 1;
          a {
            display: flex;
            flex-flow: column;
            flex-grow: 1;
            .project-item-container {
              display: flex;
              flex-flow: column;
              flex-grow: 1;
              .project-item-details {
                display: flex;
                flex-flow: column;
                flex-grow: 1;
              }
            }
          }
        }
      }
      &.home-navi {
        > li {
          margin: 0;
          &.earn-a-badge .home-navi-sub .image {
            background-image: url("../../../css/imgs/boom-imgs/marketplace/items/21.png");
          }
          &.view-projects .home-navi-sub .image {
            background-image: url("../../../css/imgs/boom-imgs/marketplace/items/22.png");
          }
          &.create-project .home-navi-sub .image {
            background-image: url("../../../css/imgs/boom-imgs/marketplace/items/23.png");
          }
          &.project-details .home-navi-sub .image {
            background-image: url("https://i.imgur.com/yxmUU5H.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            height: 240px;
          }
          // &.featured-member .home-navi-sub .image {
          //   background-image: url("https://d226u7cmiuzejk.cloudfront.net/15840.png");
          //   background-repeat: no-repeat;
          //   background-size: cover;
          //   height: 240px;
          // }
          // &.featured-member .home-navi-sub {
          //   img {
          //     border-radius: 50%;
          //     width: 150px;
          //     // margin: 24px 0 0 0;
          //     margin: 24px auto;
          //   }
          // }

          .home-navi-sub {
            overflow: hidden;
            position: relative;
            display: flex;
            flex-flow: column;
            height: 100%;
            margin: 12px;
            border-radius: 12px;
            // padding: 20px;
            background-color: #fff;

            box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
            .featured-member {
              display: flex;
              flex-flow: row;
              padding: 24px;
              align-items: center;

              @media screen and (max-width: 680px) {
                flex-flow: column;
                margin: 12px;
                img {
                  margin: 12px;
                }
              }

              img {
                border-radius: 50%;
                width: 52px;
                height: 52px;
              }
              .member-name {
                flex-grow: 1;
                width: 100%;
                margin: 12px;
                text-align: center;
                h3 {
                  margin: 0;
                }
              }
              .button-container {
                padding: 0px;
                height: 60px;
                width: 120px;
                margin: auto;
                a {
                  width: 120px;
                  float: right;
                  margin: auto;
                }
              }
            }
            .member-project-image {
              padding: 12px;
              margin: 0 24px;
              position: relative;
              text-align: center;
              img {
                border-radius: 12px;
                max-width: 240px;
                width: 60%;
              }
              .second-img {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                object-fit: contain;
                opacity: 0;
                transition: opacity 0.2s;
              }

              .second-img:hover {
                opacity: 1;
              }
            }
            .image {
              width: 100%;
              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
              }
            }
            .text {
              margin: 0 12px;
              padding: 20px;
              display: flex;
              flex-flow: column;
              // flex-grow: 1;
              text-align: center;
              white-space: pre-wrap;
            }
            .button-container {
              position: relative;
              bottom: 0;
              padding: 20px;
              width: 100%;
              a {
                line-height: 52px;
                height: 52px;
                background-color: #23d2e2;
                border-radius: 12px;
                font-weight: 700;
                width: 100%;
              }
            }
            .vivipal {
              // padding: 24px;
              text-align: center;
              img {
                max-width: 240px;
                width: 80%;
                border-radius: 12px;
                margin: 12px;
              }
              .text {
                padding: 8px;
                h2 {
                  margin-bottom: 0;
                }
              }
              .badge-item {
                .badge-item-sub {
                  padding: 0;
                }
              }
              .image {
                .vivipal-badge {
                  width: 82px;
                }
              }
            }
          }
        }
      }
    }
    .featured-project {
      .text {
        flex-grow: 1;
      }
    }
    .project-showcase {
      grid-template-columns: repeat(auto-fit, (50%));
      @media screen and (max-width: 979px) {
        grid-template-columns: repeat(auto-fit, 100%);
      }

      .text {
        flex-grow: 1;
        justify-content: center;
      }
      .project-showcase-members {
        display: grid;
        align-items: stretch;
        justify-content: center;
        grid-template-columns: repeat(auto-fit, (20%));

        @media screen and (max-width: 630px) {
          grid-template-columns: repeat(auto-fit, 25%);
        }
        padding: 20px;

        li {
          padding: 8px;
          text-align: center;
          img {
            width: 60px;
            border-radius: 50%;
            margin-bottom: 4px;
          }
          .country {
            width: 20px;
            position: absolute;
            z-index: 2;
          }
        }
      }
    }
    .view-more-container {
      margin: 12px auto 0;
      max-width: 130px;
      .right-arrow:after {
        content: " \25BA";
      }
      a {
        line-height: 52px;
        height: 52px;
        color: #4d4e58;
        border: #eaeaf5;
        border-style: solid;
        border-width: 1px;
        background-color: white;
        // background-color: #23d2e2;
        // border-radius: 25px;
        border-radius: 12px;
        font-weight: 700;
        &:hover {
          background-color: #23d2e2;
          color: white;
        }
      }
    }

    .challenge-badges {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 16px 16px;
      margin: 16px 0;
      grid-auto-rows: 1fr;
      padding: 0 16px 0 16px;

      .challenge-badge {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align: center;
        align-items: center;
        min-height: 160px;
        padding: 8px 20px;
        border-radius: 12px;
        box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
        height: 100%;

        &.primary {
          background: url("../../../css/imgs/boom-imgs/02.jpg") no-repeat center;
          background-size: cover;
          // margin: 0 16px 0 0;
        }
        &.secondary {
          background: url("../../../css/imgs/boom-imgs/01.jpg") no-repeat center;
          background-size: cover;
          // margin: 0 0 0 16px;
        }
        &.tertiary {
          background: url("../../../css/imgs/boom-imgs/popup-bg.png") no-repeat center;
          background-size: cover;
          // margin: 0 0 0 16px;
        }

        .button-container {
          max-width: 140px;
          padding: 12px;
          .button {
            line-height: 52px;
            height: 52px;
            background-color: #fff;
            border-radius: 12px;
            font-weight: 700;
            width: 100%;
            color: black;
          }
        }

        .challenge-badge-info-wrap {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          width: 80%;
          .challenge-badge-image {
            margin-right: 16px;
          }
          .challenge-badge-title,
          .challenge-badge-text {
            color: #fff;
            margin: 0;
          }

          .challenge-badge-title {
            font-size: 1.5rem;
            font-weight: 700;
          }

          .challenge-badge-text {
            font-size: 0.875rem;
            font-weight: 500;

            .bold {
              margin-right: 4px;
              font-weight: 700;
            }
          }
        }
      }
    }
    @media screen and (max-width: 680px) {
      .challenge-badges {
        .challenge-badge {
          // display: block;
          flex-direction: column;
          padding: 28px 18px;
          .challenge-badge-info-wrap {
            margin: 8px;
          }
        }
        .button-container {
          margin: auto;
        }
      }
    }
    @media screen and (max-width: 979px) {
      .challenge-badges {
        display: block;
        .challenge-badge.primary,
        .challenge-badge.secondary {
          margin: 16px 0;
        }
      }
    }

    .stats {
      display: grid;
      grid-gap: 24px;
      -ms-flex-align: start;
      align-items: start;
      grid-template-columns: repeat(auto-fit, 284px);
      justify-content: center;
      grid-auto-rows: 1fr;
      .stats-box {
        padding: 32px 28px 100px;
        border-radius: 12px;
        background-color: #fff;
        box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
        position: relative;
        height: 100%;
      }

      .stats-box.stats-active-users {
        background: url("../../../css/imgs/boom-imgs/graph/stat/07.png")
          no-repeat center bottom #fff;
        background-size: contain;
      }

      .stats-box.stats-active-users .stats-box-icon-wrap {
        background-color: #615dfa;
      }

      .stats-box.stats-visits {
        background: url("../../../css/imgs/boom-imgs/graph/stat/08.png")
          no-repeat center bottom #fff;
        background-size: contain;
      }

      .stats-box.stats-visits .stats-box-icon-wrap {
        background-color: #4f8dff;
      }

      .stats-box.stats-session-duration {
        background: url("../../../css/imgs/boom-imgs/graph/stat/09.png")
          no-repeat center bottom #fff;
        background-size: contain;
      }

      .stats-box.stats-session-duration .stats-box-icon-wrap {
        background-color: #3ad2fe;
      }

      .stats-box.stats-returning-visitors .stats-box-icon-wrap {
        background-color: #23d2e2;
      }

      .stats-box .percentage-diff {
        position: absolute;
        top: 24px;
        right: 28px;
      }

      .stats-box .stats-box-icon-wrap {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin: 0 auto;
      }

      .stats-box .stats-box-icon-wrap .stats-box-icon {
        fill: #fff;
      }

      .stats-box-icon {
        width: 20px;
        height: 20px;
      }

      .stats-box .stats-box-title {
        margin-top: 24px;
        font-size: 3rem;
        font-weight: 700;
        text-align: center;
      }

      .stats-box .stats-box-subtitle {
        margin-top: 16px;
        font-size: 1.125rem;
        font-weight: 700;
        text-align: center;
      }

      .stats-box .stats-box-text {
        margin-top: 10px;
        color: #3e3f5e;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.4285714286em;
        text-align: center;
      }
    }
  }
  .feedback-banner-desktop {
    position: relative;
    // padding: 20px;
    align-items: center;
    text-align: center;

    width: 100%;
    margin-bottom: 20px;
  }
  .more-button {
    border-radius: 12px;
  }
  .more {
    display: none;
  }
  &.hide-in-app {
    padding-top: 20px;
  }
}
