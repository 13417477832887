@import "../../../css/styles/variables";
@import "../../../css/styles/colors";

/*--------------------------------
    4. CALENDAR EVENTS PREVIEW
---------------------------------*/
.calendar-events-preview {
  padding: 32px 28px;
  border-radius: 12px;
  background-color: #fff;
  flex: 1;
  margin-top: 16px;
  
  .calendar-events-preview-title {
    font-size: 24px;
    font-weight: 500px;
    margin-bottom: 40px;
    color: $secondary-color;
    
    &.primary {
      display: block;
    }
    &.secondary {
      display: none;
      margin-top: 50px;
    }
  }

  .calendar-events-preview-subtitle {
    margin-bottom: 30px;
    color: $primary-color;
    .calendar-event-preview-list {
      margin-top: 18px;
      cursor: pointer;
      border-radius: 10px;
      padding: 10px;
      border: 1px solid transparent;

      .icon-check {
        position: absolute;
        top: -6px;
        left: -28px;
        fill: #fff;
      }

      &.active {
        &:hover {
          padding: 9px;
          border-width: 2px;
        }
      }

      &.booked {
        color:#fff;
        .calendar-event-preview {
          flex-wrap: nowrap;
          .calendar-event-preview-info {
            &::before{
              background-color: transparent;
            }
          }
        }
      }

      &.submitted {
        padding: 9px;
        border: 2px dashed $secondary-color;
        .calendar-event-preview {
          .calendar-event-preview-info {
            &::before{
              border-color: $secondary-color;
            }
          }
        }
      }

      &.workshop {
        &.active {
          border-color: $secondary-color;
          box-shadow: 2px 3px 6px 0 rgba(97, 93, 250, 0.1);
        }

        &.booked {
          background-color: $secondary-color;
          box-shadow: 2px 3px 6px 0 rgba(97, 93, 250, 0.1);
        }

        .calendar-event-preview-info {
          &::before {
            border: 2px solid $secondary-color;
          }
        }
      }

      &.freeflow {
        &.active {
          border-color: $secondary-color;
          box-shadow: 2px 3px 6px 0 rgba(35, 210, 226, 0.1);
        }

        &.submitted,
        &.booked {
          background-color: $secondary-color;
          box-shadow: 2px 3px 6px 0 rgba(97, 93, 250, 0.1);
        }

        .calendar-event-preview-info {
          &::before {
            border: 2px solid $secondary-color;
          }
        }
      }


      &.inactive {
        color: #8f91ac;
        background-color: #f9f9f9;
        box-shadow: 4px 7px 12px 0 rgba(143, 145, 172, 0.1);
        cursor: not-allowed;
      }

      &.empty {
        color: #8f91ac;
        background-color: #f9f9f9;
        box-shadow: 4px 7px 12px 0 rgba(143, 145, 172, 0.1);
        cursor: not-allowed;
        font-size: 12px;
      }
          
      .calendar-event-preview {
        margin-bottom: 28px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  
  .popup-info {
    padding: 28px 32px;
    color: $primary-color;
    p {
      margin-bottom: 10px;
    }
    .indent {
      padding-left: 10px;
    }
    .bold {
      font-weight: 700;
    }
    .title {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  .popup-close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px !important;
    height: 40px !important;
    border-radius: 80px !important;
    background-color: $secondary-color;
    cursor: pointer !important;
    position: absolute !important;
    top: -20px !important;
    right: -20px !important;
    z-index: 2;
    padding: 0 !important;
    transition: background-color .2s ease-in-out;
  
    &:hover {
      background-color: $secondary-color-hover;
    }
  
    .popup-close-button-icon {
      pointer-events: none;
    }
  }
}


/*--------------------------------
    6. CALENDAR EVENT PREVIEW
---------------------------------*/
.calendar-event-preview {
  padding-left: 72px;
  position: relative;

  .calendar-event-preview-start-time {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    .calendar-event-preview-start-time-title {
      font-size: 16px;
      font-weight: 500px;
    }

    .calendar-event-preview-start-time-text {
      margin-top: 2px;
      font-size: 14px;
      font-weight: 400px;
    }
  }

  .calendar-event-preview-info {
    position: relative;

    &::before {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: -24px;
    }

    .calendar-event-preview-title {
      font-size: 16px;
      font-weight: 500px;
      // cursor: pointer;
    }

    .calendar-event-preview-text {
      margin-top: 6px;
      color: $primary-color;
      font-size: 14px;
      font-weight: 400px;
      line-height: calc(20 / 14) * 1em;
    }

    .calendar-event-preview-time {
      margin: 14px 0 4px;
      font-size: 15px;
      font-weight: 400;

      .bold {
        font-weight: 600;
      }
    }
    .calendar-event-preview-facilitator {
      margin: 4px 0;
      font-size: 15px;
      font-weight: 400;

      .bold {
        font-weight: 600;
      }
    }
  }
}


.quota {
  display: flex;
  margin-bottom: 26px;
  padding: 0;
  flex-direction: row;
  
  
  .quota-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 20px;
    height: fit-content;
    color: $primary-color;
    .bold {
      font-weight: 700;
    }
    p {
      margin-bottom: 2px;
    }
    @media screen and (max-width: 300px) {
      margin-bottom: 5px;
    }
  }

  .quota-icon {
    fill:#fff;
    background-color: $secondary-color;
    width: 26px;
    height: 100%;
    margin: auto 4px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      width: 34px;
      margin: auto 0;
      fill: $secondary-color-hover;
      background-color:#fff;
    }
  }
}

@media screen and (max-width: 800px) {
  .calendar-events-preview {
    margin: 0;
    .calendar-events-preview-title {
      font-size: 16px;
      margin-bottom: 20px;
      &.primary {
        display: none;
      }
      &.secondary {
        display: block;
      }
    }
    .calendar-events-preview-subtitle {
      font-size: 14px;
    }
    .calendar-event-preview-title {
      font-size: 14px;
    }
  }
}