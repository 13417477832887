
.selector-container {
    position: relative;
    .note {
        position: absolute;
        top: -28px;
        right: -24px;
        color: red;
        font-size: 15px;
    }
}