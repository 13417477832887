@import "../../../css/styles/colors";
@import "../../../css/styles/variables";
@import "../../../sass/color";

.portfolio {
  .portfolio-content-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    .portfolio-cover-image {
      min-height: 20vw;
      width: 100%;
      align-items: center;
      overflow: hidden;
      height: 400px;
      img {
        height: 100%;
        width: 100%;
        border-radius: 0;
        object-fit: cover;
      }
      .image {
        min-height: 20vw;
        width: 100%;
        border-radius: 0;
      }
    }
    .portfolio-basic-info{
      display: flex;
      background-color: #fff;
      padding: 10px;
      height: 120px;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      max-width: 1024px;
      width: 100%;
      @media screen and (max-width: 700px) {
        height: 180px;
        justify-content: flex-start;
        align-items: flex-end;
      }
      .portfolio-profile-image-container {
        position: absolute;
        top: -64px;
        left: 70px;
        @media screen and (max-width: 700px) {
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .portfolio-profile-image {
          border-radius: 8px;
          overflow: hidden;
          height: 128px;
          width: 128px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 5px #fff solid;
          img {
            max-width: 100%;
          }
        }
      }
      .portfolio-title {
        width: 66%;
        padding: 30px;
        text-align: right;
        font-weight: 700;
        font-size: 28px;
        color: #183b56;
        margin-right: 30px;
        @media screen and (max-width: 700px) {
          width: 100%;
          text-align: center;
          margin-right: 0;
        }

        @media screen and (max-width: 500px) {
          font-size: 22px;
        }
      }
    }
    .tab-container {
      display: flex;
      width: 100%;
      height: 56px;
      border-top: #ddd solid 1px;
      border-bottom: #ddd solid 1px;
      justify-content: center;
      .tabs {
        padding: 6px 42px 0 42px;
        max-width: 1024px;
        width: 100%;
        display: flex;
        height: 100%;
        align-items: center;
        @media screen and (max-width: 600px) {
          padding: 6px 0 0 0;
          justify-content: space-around;
        }
        .tab {
          display: flex;
          padding: 0 18px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          height: 100%;
          border-bottom: 2px solid transparent;
          @media screen and (max-width: 600px) {
            padding: 0 8px;
          }
          .tab-text {
            font-size: 18px;
            color: $primary-color;
            @media screen and (max-width: 600px) {
              font-size: 15px;
            }
          }
          &:hover {
            .tab-text {
              color: #000;
            }
          }
          &.selected {
            border-bottom: 2px solid $secondary-color;
            .tab-text {
              color: $secondary-color;
            }
          }
        }
      }
    }
  }
}
  
.page-not-found {
  font-size: 30px;
  // font-family: $font;
  text-align: center;
  margin-top: 25px;
}
