.reset-password-page{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .loading{
    margin: 0 auto;
  }
}
