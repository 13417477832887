html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
}

.app__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  padding: 36px;
  @media only screen and (max-width: 930px) {
    padding: 72px 0 24px 0;
  }
  .chat-container {
    flex: 1;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.33) 0 1px 4px 0;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;

    @media only screen and (max-width: 930px) {
      border-radius: 0;
      overflow: auto;
      .sidebar {
        width: 100%;
      }
    }

    @media only screen and (max-width: 768px) {
      .str-chat__thread,
      .pinned-messages__container {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1000;
        width: 100%;
        max-width: 100%;
        background: white;
      }
    }
  }
}

.sidebar {
  width: 312px;
}

.popup-color {
  width: 90%;
  padding: 32px !important;
  max-width: fit-content !important;
  border-radius: 12px !important;
  background: var(--primary-color) !important;
  overflow: visible !important;
  .color-picker-container {
    background-color: #fff;
    border-radius: 9px;
  }
  .color-close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px !important;
    height: 40px !important;
    border-radius: 20px !important;
    cursor: pointer !important;
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    z-index: 2;
    padding: 0 !important;
    transition: background-color 0.2s ease-in-out;
  
    &:hover {
      background-color: var(--primary-color-alpha);
    }
  
    .color-close-button-icon {
      pointer-events: none;
    }
  }
}

.channel__container {
  height: 100%;
  width: 100%;
  min-width: 0;
  flex: 1;

  @media only screen and (max-width: 930px) {
    z-index: 5;
    position: absolute;
    top: 72px;
    bottom: 24px;
    height: auto;
  }
}

.hide {
  display: none;
}

.str-chat__thread,
.pinned-messages__container {
  width: 30%;
  min-width: 300px;
}