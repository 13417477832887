@import "../../../css/styles/variables";
@import "../../../css/styles/colors";

@keyframes highlight_blink {
  50% {
    opacity: 0.5;
  }
}
@keyframes slot-machine-g4_to__to {
  0% {
    transform: translate(246.800018px, 367px);
  }
  10% {
    transform: translate(246.800018px, 431px);
  }
  20% {
    transform: translate(246.800018px, 367px);
  }
  100% {
    transform: translate(246.800018px, 367px);
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2021-5-5 11:56:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 630px) {
}
.badge-randomizer {
  display: grid;
  width: 80%;
  grid-template-columns: repeat(auto-fit, 50%);
  @media screen and (max-width: 400px) {
    width: 100%;
  }
  .badge-randomizer-machine-container {
    margin-right: 12px;
  }
  .badge-randomizer-text-container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .badge-randomizer-text {
      width: 240px;
      height: 200px;
      margin: auto;
      position: relative;
      background-color: #ffffff;
      border-radius: 12px;
      padding: 12px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-left: 12px;

      .button-container {
        position: relative;
        bottom: 0;
        width: 100%;
        text-align:center;
        a {
          line-height: 40px;
          height: 40px;
          background-color: $secondary-color;
          border-radius: 12px;
          font-weight: 700;
          width: 200px;
          color:#ffffff;
          margin: auto;
        }
      }

      .badge-randomizer-badge {
        img {
          width: 60px;
        }
        .image {
          width: 60px;
          height: 69px;
          border-radius: 12px;
        }
      }
      .rotate {
        animation: rotation 6s infinite linear;
      }
      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        6% {
          transform: rotate(-45deg);
        }
        12% {
          transform: rotate(0deg);
        }
        18% {
          transform: rotate(-45deg);
        }
        24% {
          transform: rotate(0deg);
        }
        30% {
          transform: rotate(-45deg);
        }
        36% {
          transform: rotate(0deg);
        }
        42% {
          transform: rotate(-45deg);
        }
        48% {
          transform: rotate(0deg);
        }
        52% {
          transform: rotate(-45deg);
        }
        60% {
          transform: rotate(0deg);
        }
        66% {
          transform: rotate(-45deg);
        }
        72% {
          transform: rotate(0deg);
        }
        78% {
          transform: rotate(-45deg);
        }
        84% {
          transform: rotate(0deg);
        }
        90% {
          transform: rotate(-45deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }

      .blink {
        -webkit-animation: blink-1 1.5s both;
        animation: blink-1 1.5s both;
      }

      /* ----------------------------------------------
      * Generated by Animista on 2021-5-19 23:29:29
      * Licensed under FreeBSD License.
      * See http://animista.net/license for more info. 
      * w: http://animista.net, t: @cssanimista
      * ---------------------------------------------- */

      /**
      * ----------------------------------------
      * animation blink-1
      * ----------------------------------------
      */
      @-webkit-keyframes blink-1 {
        0%,
        50%,
        100% {
          opacity: 1;
        }
        25%,
        75% {
          opacity: 0;
        }
      }
      @keyframes blink-1 {
        0%,
        50%,
        100% {
          opacity: 1;
        }
        25%,
        75% {
          opacity: 0;
        }
      }

      .spinning:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(4, end) 900ms infinite;
        animation: ellipsis steps(4, end) 900ms infinite;
        content: "\2026"; /* ascii code for the ellipsis character */
        width: 0px;
        font-size: 80px;
      }

      @keyframes ellipsis {
        to {
          width: 60px;
        }
      }

      @-webkit-keyframes ellipsis {
        to {
          width: 60px;
        }
      }
    }
  }
  @media screen and (max-width: 630px) {
    grid-template-columns: repeat(auto-fit, 100%);

    .badge-randomizer-machine-container {
      margin-right: 0;
      .badge-randomizer-machine {
        margin: auto;
      }
    }

    .badge-randomizer-text-container {
      margin: auto;
      margin-top: 28px;
      .badge-randomizer-text {
        margin-left: 0;
      }
    }
  }
}

.badge-randomizer-machine {
  position: relative;
  width: 220px;
  margin-left: auto;
  margin-right: 0;
  // filter: drop-shadow(0px -15px 16px #afafaf);
}
.wheelSpinHolder {
  position: absolute;
  width: 100%;
  height: 75px;
  overflow: hidden;
  top: 46px;
  left: 17px;
  .wheelSpinBorder {
    position: absolute;
    top: 0;
    box-sizing: border-box;
  }
  .wheelSpinSelect {
    position: absolute;
    box-sizing: border-box;
  }
  .wheelSpinHighlightBackground {
    position: absolute;
    top: 0;
    border-left: #999 solid 8px;
    border-right: #999 solid 8px;
    box-sizing: border-box;
    border-radius: 8px;
  }
  .wheelSpinHighlight {
    position: absolute;
    top: 0;
    border-left: #c5e6fa solid 8px;
    border-right: #c5e6fa solid 8px;
    box-sizing: border-box;
    border-radius: 8px;
  }
  .wheelSpinList {
    position: relative;
    z-index: 10;
    ul {
      list-style: none;
      width: 100%;
      position: absolute;
      .prizeList{
        margin: auto;
      }
    }
    .prizeList {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #fff;
      font-family: Verdana, Geneva, sans-serif;
      font-weight: bold;
      margin: 0;
      height: 75px !important;
      a {
        display: table-cell;
        vertical-align: middle;
      }
      .image {
        height: 69px;
        width: 69px;
        border-radius: 12px;
      }
    }
  }
  .flashing {
    animation: highlight_blink 0.2s 8;
    box-shadow: inset 0 0 100px #fff;
  }
}
.wheelSpinHolder.fitImg {
  img {
    width: 100%;
    height: auto;
    display: grid;
  }
}
.wheelSpinMultipleHolder {
  .wheelSpinHolder {
    width: 32%;
    float: left;
    &:nth-child(2) {
      margin: 0 2%;
    }
  }
  &:after {
    content: "";
    display: block;
    clear: both;
  }
  .wheelSpinBorder {
    border-left: #ccc solid 5px;
    border-right: #ccc solid 5px;
  }
  .wheelSpinHighlightBackground {
    border: #999 double 20px;
  }
  .wheelSpinHighlight {
    border: #fff double 20px;
  }
  .flashing {
    box-shadow: inset 0 0 12px #fff;
  }
}
#mainHolder {
  position: absolute;
  width: 186px;
  margin: auto;
  top: 0px;
}
.fitImg {
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}
#buttonSpin {
  position: relative;
  width: 200px;
  cursor: pointer;
  margin: 20px auto;
}
#buttonStop {
  position: relative;
  width: 200px;
  cursor: pointer;
  margin: 20px auto;
}
.slot-machine-g4_to {
  animation: slot-machine-g4_to__to 3000ms linear 1 normal forwards;
  pointer-events: none;
}
.pulsate-fwd {
  -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
  animation: pulsate-fwd 0.5s ease-in-out infinite both;
}
