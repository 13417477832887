@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.welcome{
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(30,30,30,1);
  width: 100%;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  ::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }

  .welcome-background{
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    position: absolute;
    background-image: url('../../../css/imgs/boom-imgs/welcome/background2.jpg');
    background-position: 0% 0%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  }
  .welcome-language{
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 100;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    height: 32px;
    .icon{
      height: 100%;
      width: 32px;
      padding: 4px;
      position: relative;
      svg{
        height: 100%;
        width: 100%;
        .real-path{
          fill: #FFF;
        }
      }
    }
    .text{
      margin: 0 4px;
      line-height: 32px;
      color: #FFF;
    }
    &:hover{
      transition: all 0.3s;
      background-color: #FFF;
      .icon svg .real-path{
        fill: $primary-color;
      }
      .text{
        color: $primary-color;
      }
    }
  }
  .welcome-content{
    height: 100vh;
    padding: 70px 0;
    position: relative;
    display: flex;
    flex-flow: column;
    // align-items: center;
    // justify-content: center;
    overflow: auto;
    .welcome-info{
      z-index: 1;
      // margin: 0 0 80px 0;
      display: flex;
      flex-flow: column;
      align-items: center;
      color: #FFF;
      text-align: center;    
      width: 100%;
      padding: 0 20px;
      a{
        color: inherit;
        text-decoration: underline;
      }
      h2.welcome-info-pretitle{
        font-size: 24px;
        margin: 0;
      }
      .logo-image {
        -webkit-user-drag: none;
        user-select: none;
        -webkit-user-select: none;
        width: 450px;
        @media screen and (max-width: 500px) {
          width: 400px;
        }
        @media screen and (max-width: 450px) {
          width: 350px;
        }
        @media screen and (max-width: 400px) {
          width: 300px;
        }
        @media screen and (max-width: 350px) {
          width: 250px;
        }
        @media screen and (max-width: 300px) {
          width: 200px;
        }
      }
      .logo{
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
          height: 100%;
          .real-path{
            fill:#FFF;
          }
        }
      }
      .welcome-info-institution {
        font-size: 18px;
        margin-bottom: 18px;
      }
      .tab-switch{
        display: flex;
        border: 1px solid #FFF;
        border-radius: 12px;
        overflow: hidden;
        width: 100%;
        max-width: 360px;
        margin: 20px 0 0;
        .tab-switch-button{
          margin: 0;
          flex: 1;
          height: 52px;
          line-height: 52px;
          font-weight: 800;
          cursor: pointer;
          transition:0.3s all;
          &.active{
            cursor: default;
            background-color: #FFF;
            color: #000;
          }
        }
      }
    }
    .welcome-form{
      position: relative;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      .form-box{
        position: relative;
        z-index: 1;
        background-color: #FFF;
        width: 484px;
        padding: 64px;
        border-radius: 12px;
        background-color: #fff;
        box-shadow: 0 0 60px 0 rgba(94,92,154,.12);
        position: relative;
        display: none;
        &.active{
          display: block;
        }
      }
    }
  }
}


// .landing {
//   width: 100%;
//   height: 100%;
//   background: url('../../css/img/landing/landing-background.jpg') no-repeat left center;
//   background-size: cover;
//   position: fixed;
//   top: 0;
//   left: 0;

//   .landing-decoration {
//     width: 64%;
//     height: 140%;
//     border-radius: 50%;
//     background: url('../../css/img/landing/dot-texture.png') repeat left top;
//     position: absolute;
//     top: -20%;
//     right: -32%;
//     pointer-events: none;
//   }
// }
 
// .landing-info {
//   width: 584px;
//   position: absolute;
//   top: 16%;
//   left: 13%;

//   .logo {
//     display: flex;
//     justify-content: center;
//   }

//   .landing-info-pretitle,
//   .landing-info-title,
//   .landing-info-text {
//     color: $white;
//     text-align: center;
//   }

//   .landing-info-pretitle,
//   .landing-info-title {
//     text-transform: uppercase;
//   }

//   .landing-info-pretitle {
//     margin-top: 36px;
//     font-size: $fs-24;
//     font-weight: $weight-medium;
//   }

//   .landing-info-title {
//     font-family: $font-secondary;
//     font-size: $fs-104;
//     font-weight: $weight-black;
//     position: relative;
//     top: -10px;
//   }

//   .landing-info-text {
//     width: 384px;
//     margin: 30px auto 0;
//     @include paragraph(medium);
//     font-weight: $weight-medium;
//   }

//   .tab-switch {
//     margin-top: 90px;
//   }
// }

/*----------------------
    3. LANDING FORM
----------------------*/
.landing-form {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  max-width: 504px;
  width: 100%;
  padding: 0 20px;

  .form-box{
    position: absolute !important;
    background-color: #FFF;
    // width: 484px;
    padding: 36px 64px 36px 64px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 0 60px 0 rgba(94,92,154,.12);
    left: auto !important;
    margin-top: 40px;
    width: 100%;
    @media screen and (max-width: 504px) {
      border-radius: 0;
    }
  }
}

// @media screen and (min-width: 2560px) {
//   .landing-info {
//     top: 25%;
//   }

//   .landing-form {
//     right: 20%;
//   }
// }

// @media screen and (min-width: 3840px) {
//   .landing-info {
//     top: 35%;
//     left: 18%;
//   }

//   .landing-form {
//     right: 25%;
//   }
// }

// @media screen and (max-width: 1500px) {
//   .landing {
//     .landing-decoration {
//       display: none;
//     }
//   }

//   .landing-info {
//     left: 6%;

//     .landing-info-text {
//       margin-top: 10px;
//     }

//     .tab-switch {
//       margin-top: 40px;
//     }
//   }

//   .landing-form {
//     right: 80px;
//   }
// }

// @media screen and (max-width: 1365px) {
//   .landing {
//     padding: 80px 0 100px;
//     position: static;
//   }

//   .landing-info {
//     width: 100%;
//     position: static;

//     .landing-info-text {
//       display: none;
//     }
//   }

//   .landing-form {
//     width: 100%;
//     margin-top: 80px;
//     position: static;

//     .form-box {
//       margin: 0 auto;
//       position: static;

//       .form-box-decoration {
//         display: none;
//       }

//       &:first-child,
//       &:last-child {
//         margin-top: 0;
//       }
//     }
//   }
// }

// @media screen and (max-width: 600px) {
//   .landing-form {
//     .form-box {
//       width: 90%;
//     }
//   }
// }

// @media screen and (max-width: 500px) {
//   .landing-info {
//     .landing-info-title {
//       font-size: $fs-60;
//     }

//     .tab-switch {
//       width: 90%;
//       margin: 20px auto 0;

//       .tab-switch-button {
//         width: 50%;
//       }
//     }
//   }

//   .landing-form {
//     margin-top: 60px;
//   }
// }