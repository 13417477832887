@import "../../../css/styles/variables";
@import "../../../css/styles/colors";

.projects {
  font-family: $font;
  .projects-header-container{
    width:100%;
    height: 250px;
    background-color: #fff;
    .projects-header-title-container{
      max-width: $content-max-width;
      padding: 40px 0 0 50px;
      color: $primary-color;
      @media screen and (max-width: 375px) {
        padding: 40px 0 0 30px;
      }
      .projects-title{
        margin-top: 50px;
        font-size: 48px;
        font-weight: 700;
      }
      .title-description {
        font-weight: 400;
      }
    }
  }
  .separator-container{
    padding: 10px 50px;
    width:100%;
    background-color: #fff;
    border-top: 2px solid #e2eef1;
    border-bottom: 1px solid #e2eef1;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 670px) {
      flex-direction: column;
      align-items: center;
      padding: 10px; 
    }
    .search-bar-container {
      width: 100%;
      max-width: 350px;
      min-width: 250px;
      display: flex;
      justify-content: center;
      overflow: hidden;
      .search-bar {
        position: relative;
        margin: 10px 0;
        width: 80%;
        label {
          font-size: 10px;
          font-weight: 400;
          margin: auto;
        }
        input {
          font-family: $font;
          font-weight: 400;
          height: 37px;
          margin: 0;
          color:#aaabb7;
        }
        ::-webkit-input-placeholder {
          font-weight: 500;
          font-size: 13px;
        }
        &:focus-within,
        &.active {
          label {
            font-family: $font;
            background-color: #fff;
            padding: 0 6px;
            font-size: 10px;
            top: -6px;
            left: 12px;
          }
        }

      }
      .search-button {
        cursor: pointer;
        border: 0;
        background-color: $secondary-color;
        border-radius: 80px;
        width: fit-content;
        height: 37px;
        width: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font;
        margin: 10px;
        transition: all 0.2s ease-in-out;
        &:hover{
          background-color: $secondary-color-hover;
        }
        .icon-search {
          fill: #fff;
          width: 13px;
          height: 13px;
        }
        
      }
    }
    .new-project-button-container {
      height: inherit;
      display: flex;
      align-items: center;
      padding: 10px;
      .new-project-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        box-shadow: 4px 7px 12px 0 rgba(0, 0, 0, 0.10);
        height: 38px;
        line-height: 38px;
        border-radius: 80px;
        text-align: center;
        font-weight: 400;
        padding: 0 13px;
        color: #fff;
        background-color: $secondary-color;
        font-size: 14px;
        width: 200px;
        transition: all 0.2s ease-in-out;
        &:hover{
          background-color: $secondary-color-hover;
          text-decoration: none;
        }
        .new-project-button-text{
          margin: auto 5px;
        }
      }
    }
  }
 
  .body{
    margin: 20px auto;
    padding: 10px 10px 0 10px;
    .filters-container {
      display: flex;
      @media screen and (max-width: 400px){
        flex-wrap: wrap;
      }
      .filter-by-text{
        margin: 18px auto 0 auto;
        color: $primary-color;
        font-size: 15px;
        white-space: nowrap;
        @media screen and (max-width: 600px){
          margin-top: 20px;
        }
        @media screen and (max-width: 400px){
          margin-left: 10px;
        }
      }
      .filters-form {
        width: 90%;
        .form-select {
          cursor: pointer;
          font-family: $font;
          height: 38px;
          width: max-content;
          text-align: center;
          border-radius: 80px;
          font-weight: 400;
          display: block;
          margin-top: 10px;
          background-color: $secondary-color;
          color: #fff;
          font-size: 14px;
          border-width: 0;
          outline-width: 0;
          padding-left: 15px;
          box-shadow: 4px 7px 12px 0 rgba(0, 0, 0, 0.10);
          transition: all 0.2s ease-in-out;
          &:hover{
            background-color: $secondary-color-hover;
          }
          @media screen and (max-width: 1365px) {
            display: block;
            width: auto;
            margin: 10px 0 0;
          }
          @media screen and (max-width: 960px) {
            width: auto;
            margin: 10px 5px 0 5px;
          }
          @media screen and (max-width: 400px){
            font-size: 12px;
            width: max-content;
          }
          .form-option {
            background-color: #fff;
            color: $secondary-color;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            font-family: $font;
          }
        }
      }
    }
    .project-container {
      font-family: $font;
      display: flex;
      flex-direction: column;
      font-weight: lighter;
      font-size: 16px;
      .project-container-sub {
        flex-grow: 1;
      }
      .category-title{
        position: absolute;
        left: 55px;
        color: $primary-color;
        font-size: 23px;
        font-weight: 600;
        @media screen and (max-width: 460px){
          font-size: 18px;
          left: 30px;
        }
      }

    }
    .project-list {
      flex-grow: 1;
      width: 100%;
      margin: 0;
      display: grid;
      align-items: stretch;
      justify-content: center;
      grid-template-columns: repeat(auto-fit, (33%));
      @media screen and (max-width: 979px) {
        grid-template-columns: repeat(auto-fit, 50%);
      }
      @media screen and (max-width: 720px) {
        grid-template-columns: repeat(auto-fit, 100%);
      }
      li {
        margin: 0;
        height: 100%;
        display: flex;
        flex-flow: column;
      }
    }
    .carousel-button-group{
      position: absolute;
      width:fit-content;
      display: flex;
      top: 0;
      right: 0;
      .arrow-button {
        background-color: #fff;
        box-shadow: 4px 7px 12px 0 rgba(0, 0, 0, 0.10);
        border-radius: 80px;
        width: 40px;
        height: 40px;
        margin-right: 5px;
        z-index: 11;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease-in-out;
        @media screen and (max-width: 500px){
          margin-left: 5px; 
          height: 28px;
          width: 28px;
        }
        .category-arrow-icon {
          fill: #adafca;
          width: 18px;
          height: 18px;
          @media screen and (max-width: 500px){
            height: 15px;
            width: 15px;
          }
        }
        &:hover{
          background-color: $secondary-color;
          .category-arrow-icon{
            fill: #fff
          }

        }
      }
    }
    .carousel-item{
      margin: 5px;
      width: fit-content !important;
      > li {
        margin: auto;
        width: max-content;
        height: fit-content;
        cursor: pointer;
        background-color: #fff;
        border-radius: 20px;
        padding: 12px 18px;
        color: #adafca;
        box-shadow: 4px 7px 12px 0 rgba(0, 0, 0, 0.10);
        white-space: nowrap;
        transition: all 0.2s ease-in-out;
        &:hover,
        &.active {
          background-color: $secondary-color;
          color: #fff;
        }
      }
    }
    .carousel-container {
      height: 150px;
      display: flex;
      .category-label {
        width: max-content;
        height: fit-content;
        cursor: pointer;
        background-color: #fff;
        border-radius: 20px;
        padding: 8px 12px;
        margin-right: 8px;
        color: #adafca;
        box-shadow: 4px 7px 12px 0 rgba(0, 0, 0, 0.10);
        white-space: nowrap;
        transition: all 0.2s ease-in-out;
        &:hover,
        &.active {
          background-color: $secondary-color;
          color: #fff;
        }
      }
    }
    .projects-main-footer {
      text-align: center;
      h3 {
        margin-top: 12px;
      }
      .pagination {
        justify-content: center;
      }
    }
    .loading-container {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  }
  .section-filters-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 96px;
    padding: 0 28px;
    border-radius: 12px;
    box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
    background-color: #fff;
    margin-top: 30px;
    margin-bottom: 16px;
    @media screen and (max-width: 1365px) {
      flex-direction: column;
      height: auto;
      padding: 32px 28px;
    }
    @media screen and (max-width: 967px) {
      flex-direction: column;
      height: auto;
      padding: 32px 28px;
      width: 98.5%;
    }
    @media screen and (max-width: 720px) {
      flex-direction: column;
      height: auto;
      padding: 32px 28px;
      width: 98%;
    }
    @media screen and (max-width: 420px) {
      flex-direction: column;
      height: auto;
      padding: 32px 28px;
      width: 97%;
    }
    .section-filters-bar-actions {
      display: flex;
      align-items: center;
      @media screen and (max-width: 1365px) {
        &:first-child {
          margin-bottom: 40px;
        }
      }
      @media screen and (max-width: 960px) {
        width: 100%;
        justify-content: center;
      }
      .form {
        width: 100%;
        @media screen and (max-width: 1365px) {
          display: flex;
        }
        @media screen and (max-width: 960px) {
          display: block;
        }
        .form-item {
          width: 100%;
          display: flex;
          @media screen and (max-width: 979px){
            position: relative;
          }
          .form-input {
            position: relative;
            font-size: 80%;
            font-weight: 400;
            width: 300px;
            margin-right: 12px;
            @media screen and (max-width: 979px) {
              width: 100%;
              margin-bottom: 24px;
            }
            label {
              top: 16px;
              font-size: 14px;
            }
            input {
              height: 48px;
              font-size: 14px;
              margin: 0;
              @media screen and (max-width: 979px) {
                padding-right: 82px;
              }
            }
            &:focus-within,
            &.active {
              label {
                background-color: #fff;
                padding: 0 6px;
                font-size: 12px;
                top: -6px;
                left: 12px;
              }
            }
          }
          .button {
            background-color: #23d2e2;
            box-shadow: 4px 7px 12px 0 rgba(35, 210, 226, 0.2);
            width: 64px;
            height: 48px;
            margin-right: 0;
            z-index: 11;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 979px){
              position: absolute;
              top: 0;
              right: 0;
            }
            .icon-magnifying-glass {
              fill: #fff;
              width: 24px;
              height: 24px;
            }
          }
        }
        .form-select {
          display: none;
          select:focus-visible {
            outline: none;
          }
          label {
            font-size: 12px;
          }
          @media screen and (max-width: 1365px) {
            display: block;
            width: 254px;
            margin-left: 16px;
          }
          @media screen and (max-width: 960px) {
            width: 100%;
            margin: 24px 0 0;
          }
          .form-select-icon {
            fill: #3e3f5e;
            transform: rotate( 90deg);
            position: absolute;
            top: 20px;
            right: 20px;
            pointer-events: none;
          }
        }
      }
      .filter-tabs {
        display: flex;
        height: 96px;
        margin-left: 80px;
        @media screen and (max-width: 1365px) {
          display: none;
        }
        .filter-tab {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: center;
          justify-content: center;
          -ms-flex-align: center;
          align-items: center;
          width: 162px;
          height: 100%;
          border-bottom: 4px solid transparent;
          cursor: pointer;
          position: relative;
          transition: border-color .2s ease-in-out;
          .filter-tab-text {
            padding: 4px 0;
            color: #adafca;
            font-size: .875rem;
            font-weight: 700;
            transition: color .2s ease-in-out;
          }
          &.filter-tab.active {
            border-bottom-color: #23d2e2;
            .filter-tab-text {
              color: #3e3f5e;
            }
          }
        }
      }
      .button {
        height: 52px;
        line-height: 52px;
        border-radius: 12px;
        font-weight: 700;
        width: 240px;
        display: block;
        // background-color: #23d2e2;
        // box-shadow: 4px 7px 12px 0 rgba(35, 210, 226, 0.2);
        background-color: #615dfa;
        box-shadow: 4px 7px 12px 0 rgba(97, 93, 250, 0.20);
      }
    }
  }

  .project-container {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 460px) {
      padding: 0 10px;
    }
    .project-container-sub {
      flex-grow: 1;
    }
  }
  .project-list {
    flex-grow: 1;
    width: 100%;
    margin: 0;
    display: grid;
    align-items: stretch;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, (33%));
    @media screen and (max-width: 979px) {
      grid-template-columns: repeat(auto-fit, 50%);
    }
    @media screen and (max-width: 720px) {
      grid-template-columns: repeat(auto-fit, 100%);
    }
    li {
      margin: 0;
      height: 100%;
      display: flex;
      flex-flow: column;
    }
  }
  .categories {
    display: flex;
    flex-wrap: wrap;
    > li {
      width: auto;
      cursor: pointer;
      background-color: #fff;
      border-radius: 12px;
      padding: 8px 12px;
      margin-right: 8px;
      color: #adafca;
      box-shadow: 4px 7px 12px 0 rgba(0, 0, 0, 0.10);
      &:hover,
      &.active {
        background-color: #33404d;
        color: #fff;
      }
    }
  }
  .projects-main-footer {
    text-align: center;
    h3 {
      margin-top: 12px;
    }
    .pagination {
      justify-content: center;
    }
  }
  .loading-container {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}