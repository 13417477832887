.sidebar {
  display: flex;
  height: 100%;

  .sidebar__company-selection-bar {
    width: 72px;
    padding: 14px;

    .sidebar__company-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
    }
  }

  .channel-list-bar {
    display: flex;
    flex-direction: column;
    width: 240px;

    @media only screen and (max-width: 930px) {
      flex: 1;
      width: auto;
    }

    .channel-list-bar__header {
      padding-inline: 16px;
      height: var(--header-height);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .channel-list-bar__header__text {
        font-size: 1.28em;
        margin-bottom: 0;
      }
      .color-button {
        align-items: center;
        background: #fff;
        border-radius: 15px;
        box-shadow: 0 2px 7px rgba(0,0,0,.198);
        cursor: pointer;
        display: flex;
        height: 30px;
        justify-content: space-evenly;
        width: 100px;
      }
      .color-text {
        color: #0a0a0a;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: .8px;
        margin: 0;
      }
      .color-circle {
        background: var(--primary-color);
        border-radius: 11px;
        box-shadow: 0 1px 4px 0 rgba(0,0,0,.33);
        height: 22px;
        width: 22px;
      }
    }
  }
}



