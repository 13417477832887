@import "../../../css/styles/colors";
@import "../../../css/styles/variables";

.notification-item{
  padding: 18px;
  font-family: $font;
  height: 100%;
  background-color: #fff;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 8px 0px $light-gray-color;
    transform: scale(1.01);
  }
  .notif-details-container{
    display: flex;
    flex-direction:row;
    .user-image{
      margin: 0 16px 0 0;
      width: 40px;
      min-width: 40px;
      height: 40px;
      display: flex;
      overflow: hidden;
      border-radius: 20px;
      a {
        height: 100%;
        width: 100%;
        .image {
          height: 100%;
          width: 100%;
        }
      }
    }
    .badge-image{
      margin: 0 16px 0 0;
      width: 40px;
      min-width: 40px;
      height: 42px;
      display: flex;
      overflow: hidden;
      border-radius: 12px;
      a {
        height: 100%;
        width: 100%;
        .image {
          height: 100%;
          width: 100%;
        }
      }
    }    
  }
  .message {
    color: rgba(100,100,100,1);
    font-weight: 400;
    font-size: 14px;
  }
  .notif-detail{
    color: $primary-color;
    font-weight: 400px;
    font-size: 17px;
    a{
      color: $secondary-color;
      transition: all 0.3s ease-in-out;
      &:hover{
        color: $secondary-color-hover;
        text-decoration-line: none;
      }
    }
    .date-time-since{
      margin-top: 2px;
      font-size: 12px;
      color: rgba(100,100,100,1);
      margin-bottom: 10px;
    }
    .another-badge-button{
      border-radius: 80px;
      background-color: $secondary-color;
      color: #fff;
      font-weight: 400;
      width: 80px;
      font-size: 15px;
      padding: 5px;
      transition: all 0.3s ease-in-out;
      &:hover{
        background-color: $secondary-color-hover;
        color: #fff;
      }
    }
    .another-badge-button-none{
      display: none;
    }
    .project-image-container{
      height: 300px;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
      overflow: hidden;
      border-radius: 25px;
      transition: all 0.3s ease-in-out;
      img {
        width: 100%;
        height: 300px;
        border-radius: 25px;
        object-fit: cover;
        object-position: center;
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        box-shadow: 0px 0px 2px 0px $light-gray-color;
        transform: scale(1.01);
        img {
          transform: scale(1.02);
        }
      }
    }
    
  }

  
}