@import "../../../css/styles/variables";
@import "../../../css/styles/colors";

.widget-box {
  padding: 32px 28px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
 
  .comments-title {
    font-size: 32px;
    font-weight: 600;
    margin: auto 0 auto 20px;
    color:$primary-color;
    @media screen and (max-width: 979px) {
      margin: 10px 20px;
    }
  }

  .comments-detail {
    padding: 20px;
    background-color: #fff;
    .show-more {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      button {
        background: none;
        border: none;
        font-weight: 600;
        cursor: pointer; 
        transition: all 0.2s ease-in-out;
      }
      button:hover {
        color: gray;
      }
    }
    .add-comment {
      textarea {
        height: auto;
        transition: all 0.2s ease-in-out;
        &:hover{
          transform: scale(1.01);
        }
      }
      ::-webkit-input-placeholder{
        font-family: $font;
      }
      ::-webkit-input-placeholder{
        font-family: $font;
      }
      .add-comment-button {
        display: flex;
        justify-content: flex-end;
        .button-element {
          border-radius: 20px;
          width: 180px;
          .background {
            background: none;
          }
          input {
            padding-right: 40px;
            font-weight: 400;
            background-color: $secondary-color;
          }
          .button-status-image {
            z-index: 100;
          }
        }
      }
    }
  }
}